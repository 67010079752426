import {
  LOAD_SERIES_DETAIL,
  LOAD_SIMILAR_SERIES,
  LOAD_PROGRAM_TITLES,
  LOAD_SERIES_SCHEDULES,
  LOAD_SEASON_DATA,
  LOAD_FIRST_EPISODE_DATA,
  LOAD_SERIES_PAGE_CONTAINERS,
  LOAD_SEASON_ENTITLEMENTS,
  LOAD_CONTENT_USERDATA,
  LOAD_LIST_OF_TEAMS,
  LOAD_RECENT_AIRINGS,
  LOAD_LIST_OF_GAMES,
} from "./actions";

const initialState = {
  containers: null,
  content: null,
  seasonContent: null,
  similarItems: null,
  titlesContent: null,
  schedules: null,
  trailerContent: null,
  seasonDetails: null,
  firstEpisodeData: null,
  contentUserData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERIES_PAGE_CONTAINERS:
      return { ...state, containers: action.content };
    case LOAD_SERIES_DETAIL:
      return { ...state, content: action.content };
    case LOAD_SIMILAR_SERIES:
      return { ...state, similarItems: action.content, totalSimilarItems: action.total };
    case LOAD_PROGRAM_TITLES:
      return { ...state, titlesContent: action.content };
    case LOAD_SERIES_SCHEDULES:
      return { ...state, schedules: action.content };
    case LOAD_SEASON_DATA:
      return { ...state, seasonDetails: action.content };
    case LOAD_FIRST_EPISODE_DATA:
      return { ...state, firstEpisodeData: action.content };
    case LOAD_SEASON_ENTITLEMENTS:
      return { ...state, seasonEntitlements: action.content };
    case LOAD_CONTENT_USERDATA:
      return { ...state, contentUserData: action.content };
    case LOAD_LIST_OF_TEAMS:
      return { ...state, listOfTeams: action.content };
    case LOAD_RECENT_AIRINGS:
      return { ...state, recentAirings: action.content };
    case LOAD_LIST_OF_GAMES:
      return { ...state, listOfLiveGames: action.content };
    default:
      return state;
  }
};
