import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  formatAppProviderLanguage,
  getHomeStatus,
  getLink,
  getLinkName,
  getPageHierarchy,
  getPageType,
} from "../analytics/helpers";
import {
  formatTrackingValue,
  getExtraMetadata,
  getMappedUserProfileType,
  getMediaObjects,
  isRecommendation,
  getCustomerType,
} from "../utils/analytics";
import {
  SITE_NAMES,
  LINK_INFO,
  ANALYTICS_STORAGE_KEYS,
  UNKNOWN_VALUE,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_EVENT_INFO_TYPES,
  LOGIN_STATE,
  MIDDLEWARE_PLATFORM,
  ADOBE_SDK,
  EXTRA_METADATA_TYPES,
} from "../constants/analytics";
import constants from "../constants";
import { getSessionStorage, setSessionStorage } from "../utils/sessionStorage";
import { trackEvent } from "../analytics/dataLayer";

/**
 * Custom hook used to track page view events
 * @returns {Object}
 */
export default function useTrackPageView() {
  const appProvider = useSelector(({ app }) => app.provider);
  const userProfile = useSelector(({ app }) => app.userProfile);
  const isInHome = useSelector(({ app }) => app.isInHome);
  const isPageViewTracked = useRef(false);

  /**
   * Formats tracking data and tracks a page view event
   * @param {Object} pageDetails Optional page-specific data
   */
  const trackPageView = useCallback(
    (pageDetails = {}) => {
      const {
        hubs,
        pageName: pageTitle,
        feedName,
        contentId,
        extraMetadataList = [],
        episodeInfo = "",
        isInternalCampaign,
        profileEvent,
      } = pageDetails;

      if (isPageViewTracked.current) {
        return;
      }

      const { linkName, linkPosition } = getLink();
      const currentRoute = window.location.hash.slice(1);
      const pageHierarchy = getPageHierarchy(currentRoute, { hubs, pageTitle, feedName, episodeInfo });
      const { USER_PROPERTY_NAMES } = constants;

      if (!pageHierarchy) {
        return;
      }

      const formattedLinkPosition = formatTrackingValue(linkPosition);
      const formattedLinkName = formatTrackingValue(getLinkName(linkName));
      const pageName = `web/${SITE_NAMES.TELUS_TV}/${pageHierarchy.join("/")}${episodeInfo}`;
      const regExp = /[a-zA-Z]/g;
      const username = userProfile?.user?.profile?.profileData?.username;
      const firstTwoLettersString = username?.length >= 2 ? username.substring(0, 2) : "";
      // If the linkPosition is Carousel, it means a user just clicked the `Details` button on Carousel from previous page,
      // thus pageLoaded event that includes extraMetadataList-COMMERCE should also pass productImpressions
      const isProductImpressionClicked = linkPosition === LINK_INFO.CAROUSEL;
      const isRecoSwimlaneItemClicked =
        isRecommendation(formattedLinkName) && (formattedLinkPosition || "").indexOf(LINK_INFO.VIEW_ALL) === -1;
      const isRecoViewAllItemClicked =
        isRecommendation(formattedLinkName) && (formattedLinkPosition || "").indexOf(`${LINK_INFO.VIEW_ALL}:`) !== -1;
      const isSPLUS =
        userProfile?.user?.profile?.profileData?.properties?.[0]?.propertyName === USER_PROPERTY_NAMES.SPLUS;
      const isTechTrial = regExp.test(firstTwoLettersString) && firstTwoLettersString !== "tq";
      const asset =
        extraMetadataList.find((metadata) => metadata.type === EXTRA_METADATA_TYPES.MEDIA_CONTENT)?.asset ?? null;
      if (asset) asset.contentId = contentId;

      const eventDetails = {
        commerce: null,
        _telus: getMediaObjects(asset, isInternalCampaign, isProductImpressionClicked, {
          isRecoSwimlaneItemClicked,
          isRecoViewAllItemClicked,
          formattedLinkName,
          formattedLinkPosition,
        }),
        web: {
          webInteraction: null,
          webPageDetails: {
            pageViews: {
              value: 1,
            },
            name: pageName,
            URL: window.location.href,
            server: window.location.origin,
            siteSection: pageHierarchy[0],
            _telus: {
              pageName,
              previousPage: getSessionStorage(ANALYTICS_STORAGE_KEYS.PREVIOUS_PAGE_NAME),
              pageLoadTime: null,
              customSiteSection: pageHierarchy[0],
              primarySiteSection: pageHierarchy.length > 1 ? pageHierarchy.slice(0, 2).join("/") : null,
              secondarySiteSection: pageHierarchy.length > 2 ? pageHierarchy.slice(0, 3).join("/") : null,
              siteName: userProfile?.isLoggedIn
                ? isSPLUS
                  ? USER_PROPERTY_NAMES.SPLUS.toLowerCase()
                  : appProvider?.isOptik
                  ? SITE_NAMES.OPTIK_TV
                  : SITE_NAMES.PIK_TV
                : UNKNOWN_VALUE,
              pageLanguage: formatAppProviderLanguage(appProvider.lang),
              pageType: getPageType(currentRoute),
              userLoginStatus: userProfile.isLoggedIn ? LOGIN_STATE.LOGGED_IN : LOGIN_STATE.LOGGED_OUT,
              homeStatus: getHomeStatus(isInHome),
              customerRegion: (appProvider.geoProvinceCode || "").toLowerCase() ?? null,
              userHouseholdID:
                (userProfile?.masterAccountHouseholdID || userProfile?.user?.profile?.profileData?.username) ?? null,
              userAccountID: formatTrackingValue(appProvider.uuid),
              geo: appProvider.geoCityName ? `geo-${formatTrackingValue(appProvider.geoCityName)}` : null,
              packageId: null,
              middlewarePlatform: MIDDLEWARE_PLATFORM,
              sdkversion: ADOBE_SDK,
              contentId: formatTrackingValue(String(contentId || "")),
              customerType: formatTrackingValue(getCustomerType(userProfile, isTechTrial)),
              option82: formatTrackingValue(appProvider.option82 || userProfile?.user?.profile?.option82?.[0]),
              profile: userProfile?.user?.profile?.profileData?.userId ?? null,
              profileType: getMappedUserProfileType(userProfile?.user?.profile?.profileData),
              panicMode: appProvider.panicMode,
              sessionId: formatTrackingValue(getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID) || ""),
            },
          },
        },
        ...extraMetadataList.reduce(
          (preMetadata, curMetadata) => ({
            ...preMetadata,
            ...getExtraMetadata(curMetadata.type, curMetadata?.asset, isInternalCampaign, isProductImpressionClicked),
          }),
          {}
        ),
      };

      trackEvent(ANALYTICS_EVENT_TYPES.PAGE_VIEW, ANALYTICS_EVENT_INFO_TYPES.PAGE_LOAD, eventDetails);

      setSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM, "");
      setSessionStorage(ANALYTICS_STORAGE_KEYS.PREVIOUS_PAGE_NAME, pageName);

      isPageViewTracked.current = true;
    },
    [appProvider, userProfile, isInHome]
  );

  // Provide a way to reset the page view tracked state in case we
  // need to fire multiple page views within a single page component
  const resetIsPageViewTracked = useCallback(() => {
    isPageViewTracked.current = false;
  }, []);

  return { trackPageView, resetIsPageViewTracked };
}
