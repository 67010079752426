import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import SwimlaneHeader from "../SwimlaneHeader";
import swimlaneConstants from "../../shared/constants/swimlane";
import constants from "../../shared/constants";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { convertToPixelString } from "../../shared/utils";
import chevronIcon from "./assets/chevron.svg";
import { setConvivaContentSubType, toggleChannelSidePanelAction } from "../../App/state/actions";
import { buildLink } from "../../shared/analytics/helpers";
import { ANALYTICS_STORAGE_KEYS, ANALYTICS_EVENT_TYPES, LINK_INFO } from "../../shared/constants/analytics";
import { getSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import { useConvivaContentSubType } from "../../shared/hooks/useConvivaContentSubType";
import { useReducers } from "../../shared/hooks/useReducer";
import { formatTrackingValue, isRecommendation } from "../../shared/utils/analytics";
import { trackRecommendationImpressions, trackGenericAction } from "../../shared/analytics/dataLayer";
import {
  isFavouriteChannelsConfig,
  isResumeSwimlaneConfig,
  isFavouriteAssetsConfig,
} from "../../shared/utils/swimlane";
import "slick-carousel/slick/slick.css";
import "./style.scss";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
import storageConstants from "../../shared/constants/storage";
import ToolTip from "../../components/GuideToolTip/index.js";
import { setLocalStorage, getLocalStorage } from "../../shared/utils/localStorage";
import useCurrentTime from "../../shared/hooks/useCurrentTime.js";

const { ITEM_TYPES } = swimlaneConstants;
const { LAST_SELECTED_SWIMLANE_ID, LAST_SCROLL_POSITION, HAS_SEEN_RESUME_TOOLTIP, RESUME_TOOL_TIP_SESSION_START } =
  storageConstants;
const { REDUCER_TYPE } = constants;

/**
 * Component for showcasing a swimlane row
 *
 * @component
 * @param {Object} props
 */
function Swimlane(props) {
  const {
    feed,
    ThumbnailComponent,
    dimensionConfig,
    data,
    sliderSettings,
    setConvivaContentSubType,
    getSwimlaneRowNum = () => null,
    toggleChannelSidePanelAction,
    searchTerm,
    priorityEpisode,
    userProfile,
    isInHome,
    breadCrumbTitle,
    setSelectedAssetRecordingInfo,
    updateRecordingHandler,
    isFeedPageSwimlane,
    refreshFeedRef,
    config,
  } = props;

  const { provider: appProvider, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { t: translate } = useTranslation();
  const [sliderConfig, setSliderConfig] = useState(sliderSettings);
  const [sliderCount, setSliderCount] = useState(1);
  const [windowWidth] = useWindowSize();
  const [thumbnailDimension, setThumbnailDimension] = useState(
    feed?.title === translate("teams") ? dimensionConfig.teamViewAllValues : dimensionConfig.values
  );
  const [swimlaneFeed, setSwimlaneFeed] = useState(feed);
  const convivaContSubType = useConvivaContentSubType(setConvivaContentSubType);
  const swimlaneItemCount = swimlaneFeed?.items?.length;
  const isRecommendationImpressionTriggered = useRef(false);
  let swimlaneSlider = useRef(null);
  const isFavouriteChannelsFeed = isFavouriteChannelsConfig(feed?.feed);
  // This is going to be used in Part 2 of contextual menu for resume swimlane manipulation
  const isResumeSwimlane = isResumeSwimlaneConfig(feed?.feed);
  const isFavouriteAssetsFeed = isFavouriteAssetsConfig(feed?.feed);
  const { isConvivaAppTrackerEnabled } = featureToggles;
  const divElRef = useRef(null);
  const lastSelectedSwimlaneId = getSessionStorage(LAST_SELECTED_SWIMLANE_ID);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const currentTime = useCurrentTime(3600000, true);
  const activeProfile = userProfile?.user?.profile?.profileData;
  const isKidsProfile = activeProfile?.kidsProfile;

  const tooltipMessages = [
    {
      tooltipHeader: translate("Remove_resume_title"),
      tooltipText: translate("Remove_resume_body"),
      position: "resume",
      showButton: false,
    },
  ];

  useEffect(() => {
    if (isResumeSwimlane && !isKidsProfile) {
      const currentUserId = userProfile?.user?.profile?.profileData?.userId;
      let hasSeenTooltip = getLocalStorage(HAS_SEEN_RESUME_TOOLTIP);

      if (!hasSeenTooltip) {
        hasSeenTooltip = {};
      }

      if (currentUserId) {
        if (!hasSeenTooltip[currentUserId]) {
          hasSeenTooltip[currentUserId] = { seen: true, isClosedManually: false, sessionStart: currentTime };
          setIsTooltipVisible(true);
          setLocalStorage(HAS_SEEN_RESUME_TOOLTIP, hasSeenTooltip);
        } else {
          // Check if it should reappear after 72 hours
          const userSessionStart = hasSeenTooltip[currentUserId].sessionStart || 0;
          if (!hasSeenTooltip[currentUserId].isClosedManually && currentTime >= userSessionStart + 259200000) {
            setIsTooltipVisible(true);
            hasSeenTooltip[currentUserId].sessionStart = currentTime;
            setLocalStorage(HAS_SEEN_RESUME_TOOLTIP, hasSeenTooltip);
          }
        }
      }
    }
  }, [userProfile, currentTime, isResumeSwimlane, isKidsProfile]);

  const handleCloseTooltip = useCallback(() => {
    if (isResumeSwimlane) {
      setIsTooltipVisible(false);
      const currentUserId = userProfile?.user?.profile?.profileData?.userId;
      let hasSeenTooltip = getLocalStorage(HAS_SEEN_RESUME_TOOLTIP);

      if (hasSeenTooltip && currentUserId) {
        hasSeenTooltip[currentUserId].isClosedManually = true;
        setLocalStorage(HAS_SEEN_RESUME_TOOLTIP, hasSeenTooltip);
      }
    }
  }, [userProfile, isResumeSwimlane]);

  /**
   * Find the number of slides that can be occupied on the screen and returns the same
   *
   * @param {Object} dimension - dimension configuration with width and height values.
   * @param {Object} windowWidth - current window width value
   * @returns {Number} - Returns the number of slides that can be occupied on the screen
   */
  const findSliderCountForWindow = (dimension, windowWidth) => {
    let displaySliderCount = 1;

    const singleSliderSpace = dimension.width + dimension.itemSpacing;
    const totalSliderSpace = windowWidth - dimension.leftPadding;
    displaySliderCount = Math.floor(totalSliderSpace / singleSliderSpace);

    return displaySliderCount;
  };

  /**
   * Find the thumbnail dimension configuration according to the breakpoints configured.
   *
   * @param {Object} dimensionConfig - dimension configuration with responsive values
   * @returns {Number} - Returns the dimensions configured for current width of the screen.
   */
  const findResponsiveThumbnailDimension = (dimensionConfig) => {
    for (let i = 0; i < dimensionConfig.responsive.length; i++) {
      const prospectiveDims = dimensionConfig.responsive[i];

      if (windowWidth <= prospectiveDims.breakpoint) {
        return prospectiveDims.values;
      }
    }

    return dimensionConfig.values;
  };

  /**
   *
   * @param {Object} item Swimlane item object
   * @param {Number} itemIndex Swimlane item's index
   * @returns analytics callback function to trigger when the specified swimlane item is clicked
   */
  const getItemClickAnalyticsCallback = (item, itemIndex) => {
    return (userData, isDetailLinkClick = false) => {
      let linkTitle, linkExtra;
      setSessionStorage(LAST_SELECTED_SWIMLANE_ID, swimlaneFeed?.feed?.id);
      setSessionStorage(LAST_SCROLL_POSITION, window.scrollY);
      if (isDetailLinkClick) {
        linkTitle = LINK_INFO.VIEW_DETAILS;
      } else {
        setSessionStorage(ANALYTICS_STORAGE_KEYS.FINDING_METHOD, swimlaneFeed.title);
        linkTitle = swimlaneFeed.title;
        linkExtra = item.analyticsSrc?.metadata?.title;
      }

      setSessionStorage(
        ANALYTICS_STORAGE_KEYS.LINK,
        buildLink(linkTitle, getSwimlaneRowNum(swimlaneFeed.title), itemIndex, linkExtra)
      );

      // TODO: Update the On Now swimlane identification check to be correct and consistent (TCDWC-2051)
      if (swimlaneFeed.title === translate("On Now")) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.ON_NOW_START, {
          ...item.analyticsSrc,
          isItemLive: true,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.ON_NOW_START,
            getCustomContextMetadata(item.analyticsSrc, userProfile, appProvider, isInHome)
          );
        }
      } else if (swimlaneFeed.title === translate("resume")) {
        convivaContSubType.updateContSubTypeFromUserData(
          appProvider,
          {
            item: item.analyticsSrc?.metadata,
          },
          userData
        );
      } else if (searchTerm) {
        setSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM, searchTerm);
      }
    };
  };

  // Listen on window size changes and find out thumbnail dimensions for responsive view.
  // Also updates sliderCount for scroll.
  useEffect(() => {
    if (
      dimensionConfig.responsive &&
      typeof dimensionConfig.responsive === "object" &&
      swimlaneFeed?.title !== translate("teams")
    ) {
      setThumbnailDimension(findResponsiveThumbnailDimension(dimensionConfig));
    }

    setSliderCount(findSliderCountForWindow(thumbnailDimension, windowWidth));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, swimlaneFeed]);

  // Listen on responsive thumbnail changes and update sliderCount for scroll.
  useEffect(() => {
    setSliderCount(findSliderCountForWindow(thumbnailDimension, windowWidth));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailDimension]);

  useEffect(() => {
    const divElement = divElRef.current;
    const scrollPosition = getSessionStorage(LAST_SCROLL_POSITION);
    if (
      swimlaneFeed?.items.length &&
      isRecommendation(formatTrackingValue(swimlaneFeed?.title)) &&
      !isRecommendationImpressionTriggered.current
    ) {
      trackRecommendationImpressions(swimlaneFeed);
      isRecommendationImpressionTriggered.current = true;
    }
    if (lastSelectedSwimlaneId) {
      if (divElement && lastSelectedSwimlaneId === swimlaneFeed?.feed?.id) {
        window.scrollTo(0, parseInt(scrollPosition) || 0);
      }
    }
  }, [swimlaneFeed, lastSelectedSwimlaneId]);

  // Listen on sliderCount changes and update the slider config accordingly
  useEffect(() => {
    setSliderConfig((config) => {
      const allowArrows = swimlaneFeed?.items.length > sliderCount;

      return {
        ...config,
        variableWidth: true,
        slidesToScroll: sliderCount,
        arrows: allowArrows,
        nextArrow: <NextArrow dimensions={thumbnailDimension} slidesToScroll={sliderCount} />,
        prevArrow: <PrevArrow dimensions={thumbnailDimension} />,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimlaneFeed?.items, sliderCount]);

  // Reset the slider to 0-th item when new content is loaded
  useEffect(() => {
    if (swimlaneItemCount > 0) {
      swimlaneSlider.slickGoTo(0, false);
    }
  }, [swimlaneItemCount]);

  useEffect(() => {
    if (feed?.items?.length > 0 || isFavouriteChannelsFeed) setSwimlaneFeed(feed);
  }, [feed, isFavouriteChannelsFeed]);

  // Inject data into swimlanes from component factory
  useEffect(() => {
    if (data?.items?.length > 0) setSwimlaneFeed(data);
  }, [data]);

  return swimlaneFeed?.items.length || isFavouriteChannelsFeed ? (
    <div className="swimlane-row" ref={divElRef}>
      {isTooltipVisible ? (
        <ToolTip
          tooltipMessages={tooltipMessages}
          handleClose={handleCloseTooltip}
          isResumeSwimlane={isResumeSwimlane}
        />
      ) : null}
      <SwimlaneHeader
        feed={swimlaneFeed}
        getSwimlaneRowNum={getSwimlaneRowNum}
        isFavouriteChannelsFeed={isFavouriteChannelsFeed}
        toggleChannelSidePanelAction={toggleChannelSidePanelAction}
        breadCrumbTitle={breadCrumbTitle}
      />
      {swimlaneFeed.items.length > 0 && (
        <div className="carousel" id={`sl-${swimlaneFeed.feed?.id}`}>
          <Slider key={swimlaneFeed.items.length} ref={(c) => (swimlaneSlider = c)} {...sliderConfig}>
            {swimlaneFeed.items.map((item, index) => {
              item.thumbnail.dimensions = thumbnailDimension;
              return (
                <ThumbnailComponent
                  key={item.id}
                  {...item}
                  itemClickAnalyticsCallback={getItemClickAnalyticsCallback(item, index)}
                  priorityEpisode={priorityEpisode}
                  analyticsTitle={item?.analyticsSrc?.metadata.title}
                  parentId={".home"}
                  showContextMenu={isFeedPageSwimlane && !isFavouriteAssetsFeed}
                  setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
                  updateRecordingHandler={updateRecordingHandler}
                  showFavouriteContextMenuOption={!isFavouriteChannelsFeed && !isFavouriteAssetsFeed}
                  isResumeSwimlane={isResumeSwimlane}
                  refreshFeedRef={refreshFeedRef}
                  config={config}
                  swimlaneTitle={swimlaneFeed.title}
                />
              );
            })}
          </Slider>
        </div>
      )}

      {swimlaneFeed.items.length === 0 && isFavouriteChannelsFeed ? (
        <div className="carousel fav-channel-swimlane">
          <div
            style={{
              width: convertToPixelString(thumbnailDimension.width),
              marginRight: convertToPixelString(thumbnailDimension.itemSpacing),
              height: convertToPixelString(thumbnailDimension.height),
            }}
            className="swimlane-card favourite-channel-null"
            onClick={() => toggleChannelSidePanelAction(true)}
          >
            <img className="fav-channel-plus-icon" src={process.env.PUBLIC_URL + "images/plus-icon.svg"} alt="" />
          </div>
          <span className="favourite-text">{translate("set_up_favourite_channels")}</span>
        </div>
      ) : null}
    </div>
  ) : null;
}

Swimlane.propTypes = {
  feed: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.object,
  ThumbnailComponent: PropTypes.elementType.isRequired,
  sliderSettings: PropTypes.object,
  dimensionConfig: PropTypes.shape({
    values: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      itemSpacing: PropTypes.number,
      leftPadding: PropTypes.number,
    }),
    responsive: PropTypes.array,
  }),
  isFeedPageSwimlane: PropTypes.bool,
};

Swimlane.defaultProps = {
  sliderSettings: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.CONFIG,
  dimensionConfig: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS,
  data: null,
};

/**
 * Custom right chevron component to override the default slider chevron
 *
 * @component
 * @param {Object} props
 */
function NextArrow(props) {
  const { onClick, dimensions, currentSlide, slideCount, slidesToScroll } = props;
  const [showArrow, setShowArrow] = useState(true);
  useEffect(() => {
    slideCount - currentSlide <= slidesToScroll ? setShowArrow(false) : setShowArrow(true);
  }, [currentSlide, slidesToScroll, slideCount]);
  return showArrow ? (
    <div className="chevron-container-right" style={{ top: `calc(${dimensions.height / 2}px)` }}>
      <img className="chevron chevron-right" alt="chevron-right" src={chevronIcon} onClick={onClick} />
    </div>
  ) : (
    <></>
  );
}

/**
 * Custom left chevron component to override the default slider chevron
 *
 * @component
 * @param {Object} props
 */
function PrevArrow(props) {
  const { onClick, dimensions, currentSlide } = props;
  const [showArrow, setShowArrow] = useState(true);
  useEffect(() => {
    currentSlide === 0 ? setShowArrow(false) : setShowArrow(true);
  }, [currentSlide]);
  return showArrow ? (
    <div className="chevron-container-left" style={{ top: `calc(${dimensions.height / 2}px)` }}>
      <img className="chevron chevron-left" alt="chevron-left" src={chevronIcon} onClick={onClick} />
    </div>
  ) : (
    <></>
  );
}

const mapDispatchToProps = {
  setConvivaContentSubType,
  toggleChannelSidePanelAction,
};

export default connect(null, mapDispatchToProps)(Swimlane);
