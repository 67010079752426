export default Object.freeze({
  FEED: {
    route: "/feed",
  },
  PAGE: {
    name: "Page",
    route: "/PAGE",
  },
  SEARCH: {
    name: "Search",
    route: "/search",
  },
  SETTINGS: {
    name: "Settings",
    route: "/settings",
  },
  MOVIE_DETAIL_PAGE: {
    route: "/detail/movie",
  },
  SERIES_DETAIL_PAGE: {
    route: "/detail/series",
  },
  TRAILER: { route: "/ondemand/trailer" },
  MOVIE: { route: "/ondemand/movie" },
  EPISODE: { route: "/ondemand/episode" },
  GUIDE: {
    route: "/guide",
    isKidsProfileAccessRestricted: true,
  },
  CAST_DETAIL_PAGE: {
    route: "/detail/cast/",
  },
  VIEW_ALL_PAGE: {
    route: "/viewall",
  },
  SVOD_ALL_PAGE: {
    route: "/svodpackage",
  },
  SIMILAR_ITEMS: {
    route: "/similarItems",
  },
  SUB_CATEGORIES_PAGE: {
    name: "SubCategories",
    title: "Featured",
    route: "/subcategories",
  },
  LIVE_PLAYER: {
    route: "/player/live",
  },
  ON_DEMAND_PLAYER: {
    route: "/player/ondemand",
  },
  RECORDING_PLAYER: {
    route: "/player/recording",
  },
  /**
   * LIVE_PLAYER_EPISODE and ON_DEMAND_PLAYER_EPISODE are only used for analytics,
   * there's no corresponding route in App/index.js,
   * please don't map it to a different page component from other content types.
   */
  LIVE_PLAYER_EPISODE: {
    route: "/player/live/episode",
  },
  // please check LIVE_PLAYER_EPISODE's comment
  ON_DEMAND_PLAYER_EPISODE: {
    route: "/player/ondemand/episode",
  },
  EPISODE_DETAIL_PAGE: {
    route: "/detail/episode",
  },
  SEARCH_VIEW_ALL: {
    route: "/searchViewAll",
  },
  BASICS: {
    name: "Basics",
    route: "/basics",
    isKidsProfileAccessRestricted: true,
  },
  ACCOUNT: {
    name: "Account",
    route: "/account",
    isKidsProfileAccessRestricted: true,
  },
  SETTING_DETAIL: {
    name: "SettingDetail",
    route: "/settingDetail",
    isKidsProfileAccessRestricted: true,
  },
  PARENTAL_PIN: {
    name: "ParentalPin",
    route: "/parentalPin",
    isKidsProfileAccessRestricted: true,
  },
  PURCHASE_PIN: {
    name: "PurchasePin",
    route: "/purchasePin",
    isKidsProfileAccessRestricted: true,
  },
  RECORDINGS: {
    name: "Recordings",
    route: "/recordings",
    isKidsProfileAccessRestricted: true,
  },
  PROFILE_SELECT: {
    name: "ProfileSelect",
    route: "/profile/select",
  },
  PROFILE_EDIT: {
    name: "ProfileEdit",
    route: "/profile/edit",
  },
  PROFILE_ADD: {
    name: "ProfileAdd",
    route: "/profile/add",
  },
});
