/**
 * load core modules
 */
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
/* load core modules end */

/**
 * load child components
 */
import Wall from "../../components/Wall";
import WallContent from "../../components/WallContent";
import OptikButton from "../../components/OptikButton";
import OrderCTA from "../../components/OrderCTA";
import WaysToOrderPrompt from "../../components/WaysToOrderPrompt";
import RecordingModal from "../../components/RecordingModal";
import SimilarItems from "./SimilarItems";
import CastAndCrew from "./CastAndCrew";
import Schedules from "../../components/Schedules";
import SeoPageTags from "../../components/SeoPageTags";
import WaysToWatchPrompt from "../../components/WaysToWatchPrompt";
import RatingUI from "../../components/Rating";
import WatchOnDemandCTA from "./WatchOnDemandCTA";
import Trailer from "./Trailer";
import PurchaseModal from "../../components/PurchaseModal";
import Favourite from "./Favourite";
import WatchLiveCTA from "./WatchLiveCTA";
import RecordingCTA from "./RecordingCTA";
import EpisodeWallContent from "./EpisodeWallContent";
/* load child components end */

/**
 * load custom hooks
 */
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import useDetailsPageCompositor from "../../shared/hooks/useDetailsPageCompositor";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { useReducers } from "../../shared/hooks/useReducer";
import { useConvivaContentSubType } from "../../shared/hooks/useConvivaContentSubType";
import { useRecordings } from "../../shared/hooks/useRecordings";
/* load custom hooks end */

/**
 * load action methods
 */
import {
  LOAD_PROGRAM_DETAIL,
  LOAD_SCHEDULES,
  LOAD_CONTENT_USERDATA,
  loadContentItemDetailPageItems,
  LOAD_CONTENT_ITEM_PAGE_CONTAINERS,
  LOAD_ON_DEMAND_OPTIONS,
  updateOnDemandOptions,
  loadContentUserData,
} from "./state/actions";
import { SHOW_PURCHASE_ACKNOWLEDGEMENT } from "../PlayerPage/state/actions.js";
import {
  resetAction,
  loadChannels,
  showToastNotification,
  loadUserSubscribedChannels,
  showModalPopup,
  setConvivaContentSubType,
  ADD_FAVOURITE,
  DELETE_FAVOURITE,
} from "../../App/state/actions";
import { showPurchaseAcknowledgement } from "../PlayerPage/state/actions";
/* load action methods end */

/**
 * load utilities
 */
import { getAVSKeyArtImage, getAVSPosterArtImage } from "../../shared/utils/image";
import { getSubTextLine1, getGenreInOneLine, getSeriesInfo } from "../../shared/utils/feedHelper";
import { handleLogin } from "../../shared/utils/login";
import { setSessionStorage, getSessionStorage } from "../../shared/utils/sessionStorage";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { isPinMaxLimitReach, getAutoGeneratedObject } from "../../shared/utils";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import {
  ANALYTICS_EVENT_TYPES,
  MAPPED_CONTENT_TYPES,
  EXTRA_METADATA_TYPES,
  ACTION_VALUES,
  WEB_ACTION_EVENT_NAMES,
} from "../../shared/constants/analytics";
import { getAAVideoPlayType } from "../../shared/analytics/helpers";
import { setNRAttribute } from "../../shared/analytics/newRelic";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../../shared/constants/index";
import errors from "../../shared/constants/error";
import routeConstants from "../../shared/constants/routes";
import playerConstants from "../../shared/constants/player";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import { NR_CUSTOM_ATTRIBUTES } from "../../shared/constants/newRelic";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
/* load constants end */

/** load middleware */
import middleware from "../../shared/middleware";

/** declare/destructure constants */
const outHomeIcon = process.env.PUBLIC_URL + "/images/Out_of_home.svg";
const unSubscribedIcon = process.env.PUBLIC_URL + "/images/key-icon.svg";
const playIcon = process.env.PUBLIC_URL + "/images/play-icon.svg";
const {
  IMAGES,
  CONTENT_ITEM_TYPES,
  PAGE_CONTENT_ITEM_TYPES,
  CONTAINER_TYPES,
  MODAL_TYPES,
  REDUCER_TYPE,
  LOGIN_BRANDS,
  SPORTEVENT,
} = constants;
const { ON_DEMAND_PLAYER, LIVE_PLAYER, EPISODE_DETAIL_PAGE } = routeConstants;
const { PLAYBACK_TYPES } = playerConstants;
const { AVS_ERROR_CODES } = errors;

/** declare/destructure middleware functions */
const { makePurchase } = middleware;

import "./style.scss";

/**
 * Content Item Detail page component
 * @component
 */
function DetailPage() {
  const match = useRouteMatch();
  const {
    params: { uriType, uriSubType, itemType, contentId, preferredScheduleId },
  } = match;

  const { pathname } = useLocation();

  const vodSubType = pathname.includes(EPISODE_DETAIL_PAGE.route)
    ? CONTENT_ITEM_TYPES.episode
    : CONTENT_ITEM_TYPES.movie;

  const detailUri = `/${uriType}/${uriSubType}/${itemType}/${contentId}`;

  const contentType = itemType;

  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const dispatch = useDispatch();
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { performPlaybackChecks } = usePlaybackChecks();
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();
  const { contentMetadata } = useDetailsPageCompositor(vodSubType);

  const { contentUserData, onDemandOptions, liveOptions } = useReducers(REDUCER_TYPE.CONTENT_ITEM);

  const { isRented } = useReducers(REDUCER_TYPE.ON_DEMAND_PLAYER);
  const {
    provider: appProvider,
    userProfile,
    channelMapInfo,
    isInHome,
    subscribedChannels,
    featureToggles,
  } = useReducers(REDUCER_TYPE.APP);
  const { isLookbackEnabled, isUserProfilesEnabled, isConvivaAppTrackerEnabled, isKoodoLoginEnabled } = featureToggles;
  const isUserLoggedIn = userProfile?.isLoggedIn ? true : false;

  const [selectedPurchasePackage, setSelectedPurchasePackage] = useState(false);
  const [showWaysToOrder, setShowWaysToOrder] = useState(null);
  const [showWaysToWatch, setShowWaysToWatch] = useState(false);
  const [showRecordingNowBadge, setShowRecordingNowBadge] = useState(false);
  const [seriesInfo, setSeriesInfo] = useState(null);

  const productDetails = useRef(null);
  const convivaContSubType = useRef(useConvivaContentSubType(setConvivaContentSubType, dispatch));
  const isPlayableOnDemand = useRef(null); // ref to check if on demand play options are available for the movie

  const {
    schedules,
    lookbackProgram,
    lookbackHoursLeft,
    currentAndUpcomingSchedules,
    firstLiveProgram,
    stationId,
    isLiveProgramOOH,
    isPlayableLive,
  } = liveOptions || {};

  const {
    watchOptions,
    trailer,
    progressTime,
    unifiedAssetExtendedMetadata,
    tamsId,
    playbackId,
    onDemandContent,
    purchasePackages,
    allOnDemandItems,
    subscribedWatchOptions,
  } = onDemandOptions || {};

  const {
    isMR,
    selectedAssetRecordingInfo,
    showRecordingModal,
    handleRecordingAction,
    showRecordingCTALoading,
    primaryRecordingCTA,
    closeRecordingModal,
    scheduleRecording,
    editRecordingHandler,
    openCancelRecordingModal,
  } = useRecordings(currentAndUpcomingSchedules, preferredScheduleId, contentMetadata, setShowRecordingNowBadge);

  const getMediaNodeMetadata = useCallback(() => {
    return {
      isItemLive: false,
      mappedContentType: MAPPED_CONTENT_TYPES.TVOD,
      ...productDetails.current,
    };
  }, []);

  useEffect(() => {
    return () => {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, null);
      isPlayableOnDemand.current = null;
      setSelectedPurchasePackage(null);
      setShowWaysToOrder(false);
      setShowWaysToWatch(false);
      dispatch(resetAction(LOAD_CONTENT_ITEM_PAGE_CONTAINERS, "content"));
      dispatch(resetAction(LOAD_PROGRAM_DETAIL, "content"));
      dispatch(resetAction(ADD_FAVOURITE, "content"));
      dispatch(resetAction(DELETE_FAVOURITE, "content"));
      dispatch(resetAction(LOAD_SCHEDULES, "content"));
      dispatch(resetAction(LOAD_CONTENT_USERDATA, "content"));
      dispatch(resetAction(LOAD_ON_DEMAND_OPTIONS, "content"));
    };
  }, [contentId, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadContentItemDetailPageItems(appProvider, detailUri, CONTAINER_TYPES.CONTAINERS));
  }, [appProvider, detailUri, dispatch, vodSubType]);

  useEffect(() => {
    if (isUserLoggedIn && !contentUserData) {
      dispatch(loadContentUserData(appProvider, contentId, contentType));
    }
  }, [appProvider, isUserLoggedIn, contentId, contentType, dispatch, contentUserData]);

  useEffect(() => {
    if (playbackId) {
      convivaContSubType.current.setPlaybackId(playbackId);
      isPlayableOnDemand.current = true;
    } else {
      isPlayableOnDemand.current = false;
    }
  }, [playbackId]);

  useEffect(() => {
    if (onDemandContent) {
      productDetails.current = {
        isItemVod: true,
        isItemLive: false,
      };
    }
  }, [onDemandContent]);

  /**
   * Load channels data if it doesn't exist yet & user is logged in
   */
  useEffect(() => {
    if (isUserLoggedIn && !channelMapInfo && appProvider) {
      dispatch(loadChannels(appProvider));
    }
  }, [appProvider, channelMapInfo, isUserLoggedIn, dispatch]);

  /**
   * Page view analytics trigger
   */
  useEffect(() => {
    if (contentMetadata?.metadata && !isUserLoggedIn) {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, contentMetadata.metadata.title);
      productDetails.current = {
        ...productDetails,
        ...contentMetadata,
        route: window.location.hash,
      };

      // Add TAMS ID
      if (productDetails?.metadata && !productDetails.metadata.externalId) {
        productDetails.metadata.externalId = tamsId;
      }
      if (isUserLoggedIn && isPlayableOnDemand.current === null) {
        return;
      }
      trackPageView({
        pageName: contentMetadata.metadata.title,
        contentId: contentMetadata.metadata.contentId || playbackId,
        productId: contentMetadata.metadata.extendedMetadata?.dlum?.umId,
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.MEDIA_CONTENT,
            asset: {
              isItemLive: !!firstLiveProgram,
              ...productDetails.current,
              mappedContentType: isPlayableOnDemand.current ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
            },
          },
          { type: EXTRA_METADATA_TYPES.COMMERCE },
          ...(getSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM)
            ? [{ type: EXTRA_METADATA_TYPES.SEARCH_CLICK }]
            : []),
        ],
      });
    }
    return () => {
      resetIsPageViewTracked();
    };
  }, [contentMetadata, trackPageView, resetIsPageViewTracked, tamsId, isUserLoggedIn, firstLiveProgram, playbackId]);

  useEffect(() => {
    if (!subscribedChannels && isUserLoggedIn && appProvider) {
      dispatch(loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled));
    }
  }, [
    subscribedChannels,
    isUserLoggedIn,
    appProvider,
    dispatch,
    userProfile,
    cancelTokenSource,
    isUserProfilesEnabled,
  ]);

  useEffect(() => {
    if (schedules?.length > 0 && firstLiveProgram) {
      productDetails.current = {
        ...productDetails.current,
        isItemLive: true,
        channel: {
          ...firstLiveProgram.channel,
          channelNumber: getRegionalChannelNumber(firstLiveProgram.channel, appProvider?.channelMapID),
        },
      };
    }
  }, [schedules, appProvider, firstLiveProgram]);

  // After we have purchased a vod asset that is blocked (parental or otherwise) from playback, we show purchase acknowledgement on detail page itself
  useEffect(() => {
    if (isRented) {
      dispatch(showToastNotification(translate("purchase_success_notification")));
    }
    return () => dispatch(resetAction(SHOW_PURCHASE_ACKNOWLEDGEMENT, "content"));
  }, [isRented, translate, dispatch]);

  useEffect(() => {
    if (watchOptions?.length > 0) {
      if (purchasePackages?.length > 0) {
        convivaContSubType.current.updateContSubType(purchasePackages);
      }
      convivaContSubType.current.updateWatchAsset(watchOptions);
    }
  }, [watchOptions, purchasePackages]);

  // Effect for fetching series info in case of episodes
  useEffect(() => {
    const fetchSeriesRoute = async () => {
      const isSportsEventPage = contentType === SPORTEVENT;
      const seriesData = await getSeriesInfo(contentMetadata, appProvider, isSportsEventPage);
      if (!seriesInfo && seriesData) {
        setSeriesInfo(seriesData);
      }
    };
    if (contentMetadata && vodSubType === CONTENT_ITEM_TYPES.episode) {
      fetchSeriesRoute();
    }
  }, [appProvider, contentMetadata, seriesInfo, vodSubType, contentType]);

  const toastClickHandler = useCallback(
    (type) => {
      if (type === "outHome") {
        dispatch(showToastNotification(translate("message_in_home")), outHomeIcon);
      } else if (type === "browser") {
        dispatch(showToastNotification(translate("error_browser_restriction")), null, {
          media: { isItemLive: !!firstLiveProgram, ...productDetails.current },
        });
      } else if (type === "geoblock") {
        dispatch(showToastNotification(translate("error_program_not_available_region")));
      } else if (type === "geofence") {
        dispatch(showToastNotification(translate("error_program_not_available_region")));
      } else if (type === "subscribe") {
        dispatch(showToastNotification(translate("not_subscribed_restriction")), unSubscribedIcon, {
          media: { isItemLive: !!firstLiveProgram, ...productDetails.current },
        });
      } else {
        return false;
      }
    },
    [dispatch, translate, firstLiveProgram]
  );

  const onPlayClick = useCallback(
    (type, contentMetadata) => {
      if (toastClickHandler(type) === false) {
        if (subscribedWatchOptions?.length > 1 && !progressTime) {
          setShowWaysToWatch(true);
        } else {
          setSessionStorage(
            ANALYTICS_STORAGE_KEYS.LINK,
            `${LINK_INFO.ON_DEMAND_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
          );
          performPlaybackChecks(
            contentMetadata,
            PLAYBACK_TYPES.MOVIE,
            playbackId,
            PAGE_CONTENT_ITEM_TYPES.vod,
            ON_DEMAND_PLAYER.route,
            getMediaNodeMetadata
          );
        }
      }
    },
    [toastClickHandler, performPlaybackChecks, getMediaNodeMetadata, progressTime, subscribedWatchOptions, playbackId]
  );

  const openPurchaseModal = useCallback(
    (purchasePackage = null) => {
      if (purchasePackage) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.ADD_TO_CART, {
          asset: getMediaNodeMetadata(),
          purchasePackage,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.ADD_TO_CART,
            getCustomContextMetadata(getMediaNodeMetadata(), userProfile, appProvider, isInHome)
          );
        }
        setSelectedPurchasePackage(purchasePackage);
      }
    },
    [appProvider, getMediaNodeMetadata, isConvivaAppTrackerEnabled, isInHome, userProfile]
  );

  const closePurchaseModal = useCallback(() => {
    setSelectedPurchasePackage(null);
  }, []);

  const purchaseItem = useCallback(
    (selectedPackage, pin, disallowPurchase) => {
      if (disallowPurchase) disallowPurchase(true); // disabling purchase CTA to prevent users from spamming
      const promise = makePurchase(appProvider, selectedPackage, pin, cancelTokenSource)
        .then(({ purchaseDetails }) => {
          trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE, {
            appProvider,
            asset: getMediaNodeMetadata(),
            purchaseDetails,
            purchasePackage: selectedPackage,
          });
          if (isConvivaAppTrackerEnabled) {
            trackConvivaCustomEvent(
              ANALYTICS_EVENT_TYPES.PURCHASE,
              getCustomContextMetadata(getMediaNodeMetadata(), userProfile, appProvider, isInHome)
            );
          }
          setSelectedPurchasePackage(null);
          dispatch(showPurchaseAcknowledgement());
          if (pin) {
            trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK, getMediaNodeMetadata());
            if (isConvivaAppTrackerEnabled) {
              trackConvivaCustomEvent(
                ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK,
                getCustomContextMetadata(getMediaNodeMetadata(), userProfile, appProvider, isInHome)
              );
            }
          }
          setSessionStorage(
            ANALYTICS_STORAGE_KEYS.LINK,
            `${LINK_INFO.PURCHASE_PLAY};${LINK_INFO.PURCHASE_PROMPT};${getAAVideoPlayType()}`
          );
          performPlaybackChecks(
            null,
            PLAYBACK_TYPES.MOVIE,
            selectedPackage.contentId,
            PAGE_CONTENT_ITEM_TYPES.vod,
            ON_DEMAND_PLAYER.route,
            getMediaNodeMetadata,
            null,
            null,
            (purchasedMovieUserData) => dispatch(updateOnDemandOptions(purchasedMovieUserData?.containers?.[0]))
          );
        })
        .catch((err) => {
          console.error("Failed to make purchase", err);
          if (disallowPurchase) disallowPurchase(false); // enabling the purchase CTA again in rare case of purchase API call failing
          if (AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code) || isPinMaxLimitReach(err)) {
            throw err;
          } else {
            dispatch(showToastNotification(translate("message_purchase_failed")));
          }
        });

      return promise;
    },
    [
      appProvider,
      cancelTokenSource,
      dispatch,
      performPlaybackChecks,
      isInHome,
      userProfile,
      isConvivaAppTrackerEnabled,
      translate,
      getMediaNodeMetadata,
    ]
  );

  let pageTitle = contentMetadata?.metadata ? contentMetadata.metadata.title : "";

  const progressPercentage = useMemo(() => {
    if (progressTime > 0 && contentMetadata?.metadata?.duration) {
      return (progressTime / contentMetadata.metadata.duration) * 100;
    }
    return null;
  }, [contentMetadata, progressTime]);

  const getEntitlementType = useCallback(
    (entitlement, movieType) => {
      let label, icon, className, type;
      if (entitlement?.isChannelNotSubscribed) {
        label = "available_tv_mobile";
        icon = playIcon;
        className = "restricted";
        type = "browser";
      } else if (
        (entitlement?.isContentOOHBlocked || (firstLiveProgram && !lookbackProgram && isLiveProgramOOH)) &&
        !isInHome
      ) {
        label = "action_ooh";
        icon = outHomeIcon;
        className = "restricted";
        type = "outHome";
      } else if (entitlement?.isPlatformBlacklisted) {
        label = movieType === "play" ? "available_tv_mobile" : "";
        icon = playIcon;
        className = "restricted";
        type = "browser";
      } else if (entitlement?.isSportBlackoutBlocked) {
        label = "";
        icon = playIcon;
        className = "restricted";
        type = "browser";
      } else if (movieType === "play") {
        let ctaType;
        if (progressPercentage) {
          label = "resume_on_demand";
          ctaType = "resume";
        } else {
          label = "action_play_on_demand";
          ctaType = "play";
        }
        if (vodSubType === CONTENT_ITEM_TYPES.episode) {
          if (uaEpisodeObject.isSeasonAutoGenerated || uaEpisodeObject.isEpisodeAutoGenerated) {
            label = translate(`${ctaType}_on_demand`);
          } else {
            const catalogInfo = contentMetadata.metadata;
            label =
              catalogInfo && catalogInfo.season
                ? catalogInfo.episodeNumber
                  ? `${translate(ctaType)} S${catalogInfo.season} E${catalogInfo.episodeNumber} ${translate(
                      "on_demand"
                    )}`
                  : `${translate(ctaType)} S${catalogInfo.season} ${translate("on_demand")}`
                : catalogInfo.episodeNumber
                ? `${translate(ctaType)} E${catalogInfo.episodeNumber} ${translate("on_demand")}`
                : translate(`${ctaType}_on_demand`);
          }
        }
        icon = playIcon;
        type = "play";
      } else if (lookbackProgram) {
        label = "restart_live_tv";
        icon = playIcon;
        className = "play";
      } else if (movieType === "live") {
        label = "action_live";
        icon = playIcon;
        type = "live";
      }

      if (entitlement?.isGeoBlocked) {
        type = "geoblock";
      } else if (entitlement?.isGeofencedBlocked) {
        type = "geofence";
      }
      return {
        label: label,
        icon: icon,
        className: className,
        type: type,
      };
    },
    [
      progressPercentage,
      lookbackProgram,
      isInHome,
      isLiveProgramOOH,
      firstLiveProgram,
      contentMetadata,
      vodSubType,
      translate,
      uaEpisodeObject,
    ]
  );

  const handleLiveItemClick = useCallback(
    (type) => {
      setSessionStorage(
        ANALYTICS_STORAGE_KEYS.LINK,
        `${LINK_INFO.LIVE_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
      );
      if (toastClickHandler(type) === false) {
        performPlaybackChecks(
          null,
          null,
          stationId,
          PAGE_CONTENT_ITEM_TYPES.live,
          LIVE_PLAYER.route,
          getMediaNodeMetadata,
          null,
          lookbackProgram?.channel || null,
          null,
          lookbackProgram || null
        );
      }
    },
    [toastClickHandler, getMediaNodeMetadata, lookbackProgram, performPlaybackChecks, stationId]
  );

  const upSellComponent = useMemo(() => {
    const upSellLink = isAppLanguageFrench ? "telus.com/moncompte" : "telus.com/mytelus";
    const upSellText = translate("not_subscribed_restriction").split(upSellLink);
    return (
      <span>
        {upSellText[0]}
        <a href={`https://www.${upSellLink}`} target="_blank" rel="noopener noreferrer" className="up-sell-link">
          {upSellLink}
        </a>
        {upSellText[1]}
      </span>
    );
  }, [isAppLanguageFrench, translate]);

  const closeWaysToWatchPrompt = () => {
    setShowWaysToWatch(false);
  };

  const closeWaysToOrderPrompt = () => {
    setShowWaysToOrder(false);
  };

  const upSellString = useMemo(() => {
    return playbackId === 0 && isPlayableLive === false && purchasePackages?.length === 0 ? (
      <div className="up-sell-message">
        <img
          src={process.env.PUBLIC_URL + "/images/key-icon.svg"}
          className="up-sell-icon"
          alt={translate("subscribe")}
          data-testid="unsubscribedIcon"
        />
        {upSellComponent}
      </div>
    ) : null;
  }, [playbackId, isPlayableLive, purchasePackages, translate, upSellComponent]);

  const orderCTA = useMemo(() => {
    return (
      !playbackId &&
      purchasePackages?.length > 0 && (
        <OrderCTA
          purchasePackages={purchasePackages}
          onClickHandler={() => {
            if (purchasePackages?.length > 1) {
              setShowWaysToOrder(true);
            } else if (purchasePackages?.length === 1) {
              openPurchaseModal(purchasePackages[0]);
            }
          }}
        />
      )
    );
  }, [playbackId, purchasePackages, openPurchaseModal]);

  const uaEpisodeObject = contentMetadata?.metadata && getAutoGeneratedObject(contentMetadata?.metadata);

  const WallContentComponent = ({ children }) => {
    return vodSubType === CONTENT_ITEM_TYPES.episode ? (
      <EpisodeWallContent
        playbackId={playbackId}
        progressTime={progressTime}
        contentMetadata={contentMetadata}
        isRecordingNow={showRecordingNowBadge}
        uaEpisodeObject={uaEpisodeObject}
        hasCC={
          contentMetadata.metadata.extendedMetadata?.hasCC ||
          firstLiveProgram?.extendedMetadata?.hasCC ||
          unifiedAssetExtendedMetadata?.hasCC
        }
        hasDV={
          contentMetadata.metadata.extendedMetadata?.hasDV ||
          firstLiveProgram?.extendedMetadata?.hasDV ||
          unifiedAssetExtendedMetadata?.hasDV
        }
        seriesInfo={seriesInfo}
      >
        {children}
      </EpisodeWallContent>
    ) : (
      <WallContent
        recordingBadge={
          showRecordingNowBadge ? (
            <img
              src={
                isAppLanguageFrench
                  ? process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg"
                  : process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg"
              }
              alt=""
            />
          ) : null
        }
        title={contentMetadata.metadata.title}
        description={contentMetadata.metadata.longDescription}
        line1={getSubTextLine1(
          contentMetadata.metadata,
          true,
          isAppLanguageFrench,
          firstLiveProgram?.metadata,
          (unifiedAssetExtendedMetadata?.rt &&
            Object.keys(unifiedAssetExtendedMetadata.rt).length > 0 &&
            unifiedAssetExtendedMetadata.rt) ||
            contentMetadata.metadata.extendedMetadata?.dlum?.rt,
          unifiedAssetExtendedMetadata,
          (unifiedAssetExtendedMetadata?.cinoche &&
            Object.keys(unifiedAssetExtendedMetadata.cinoche).length > 0 &&
            unifiedAssetExtendedMetadata.cinoche) ||
            contentMetadata.metadata.extendedMetadata?.dlum?.cinoche
        )}
        progressPercentage={progressPercentage}
        isShowMoreButton={true}
      >
        {children}
      </WallContent>
    );
  };

  return (
    <div
      className={classNames({
        "detail-movie": vodSubType === CONTENT_ITEM_TYPES.movie,
        "episode-detail": vodSubType === CONTENT_ITEM_TYPES.episode,
      })}
    >
      <SeoPageTags title={pageTitle} keywords={["optik", "telus"]} />
      {contentMetadata?.metadata && (
        <React.Fragment>
          <div
            className={classNames({
              "movie-wall-wrapper": vodSubType === CONTENT_ITEM_TYPES.movie,
              "episode-wall-wrapper": vodSubType === CONTENT_ITEM_TYPES.episode,
            })}
          >
            {vodSubType === CONTENT_ITEM_TYPES.episode ? (
              seriesInfo?.seriesDetailContent && (
                <Wall cover={getAVSKeyArtImage(seriesInfo.seriesDetailContent, IMAGES.ASPECT_RATIOS.DIM_9x16)} />
              )
            ) : (
              <Wall cover={getAVSKeyArtImage(contentMetadata.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16)} />
            )}
            <div
              className={classNames({
                "content-wrapper": vodSubType === CONTENT_ITEM_TYPES.movie,
                "episode-info": vodSubType === CONTENT_ITEM_TYPES.episode,
              })}
            >
              <WallContentComponent>
                {isUserLoggedIn ? (
                  <div className="wall-children">
                    {upSellString}
                    <WatchOnDemandCTA
                      watchOptions={watchOptions}
                      getEntitlementType={getEntitlementType}
                      onPlayClick={onPlayClick}
                      playbackId={playbackId}
                    />
                    <WatchLiveCTA
                      lookbackProgram={lookbackProgram}
                      firstLiveProgram={firstLiveProgram}
                      lookbackHoursLeft={lookbackHoursLeft}
                      getEntitlementType={getEntitlementType}
                      handleLiveItemClick={handleLiveItemClick}
                    />
                    {orderCTA}
                    {currentAndUpcomingSchedules ? (
                      <RecordingCTA
                        handleRecordingAction={handleRecordingAction}
                        showRecordingCTALoading={showRecordingCTALoading}
                        primaryRecordingCTA={primaryRecordingCTA}
                      />
                    ) : null}
                    {vodSubType === CONTENT_ITEM_TYPES.movie ? (
                      <Favourite
                        contentMetadata={contentMetadata}
                        contentType={contentType}
                        productDetails={productDetails.current}
                        isPlayableOnDemand={isPlayableOnDemand.current}
                      />
                    ) : null}
                    {vodSubType === CONTENT_ITEM_TYPES.movie ? (
                      <Trailer
                        watchOptions={watchOptions}
                        trailer={trailer}
                        getMediaNodeMetadata={getMediaNodeMetadata}
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="wall-children">
                    <div className="detail-page-cta login">
                      <OptikButton
                        label={translate("login")}
                        onClickHandler={() => {
                          setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.LOGIN};${LINK_INFO.SUB_NAV}`);
                          handleLogin(appProvider, LOGIN_BRANDS.TELUS, isKoodoLoginEnabled);
                        }}
                      />
                    </div>
                  </div>
                )}
              </WallContentComponent>
            </div>
          </div>
          <div>
            <div>
              <Schedules
                schedules={isLookbackEnabled ? schedules : currentAndUpcomingSchedules}
                onItemClick={openPurchaseModal}
                toastClickHandler={toastClickHandler}
                allOnDemandItems={allOnDemandItems}
                openRecordingModal={handleRecordingAction}
                vodPlaybackType={vodSubType}
                assetDuration={contentMetadata.metadata.duration}
                isGeoBlocked={watchOptions?.some((userdata) => userdata?.entitlement?.isGeoBlocked === true)}
              />
            </div>
            <SimilarItems detailUri={detailUri} contentMetadata={contentMetadata} />
            <CastAndCrew contentMetadata={contentMetadata} />
            {contentMetadata.metadata.extendedMetadata?.dlum?.rt && (
              <div className="detail-rating-info">
                <RatingUI content={contentMetadata.metadata} />
              </div>
            )}
          </div>
          {showWaysToWatch ? (
            <WaysToWatchPrompt
              watchOptions={subscribedWatchOptions}
              closePrompt={closeWaysToWatchPrompt}
              vodPlaybackType={vodSubType}
            />
          ) : null}
          {showRecordingModal && selectedAssetRecordingInfo && (
            <RecordingModal
              closeModal={closeRecordingModal}
              recordingInfo={selectedAssetRecordingInfo}
              scheduleRecordingHandler={scheduleRecording}
              editRecordingHandler={editRecordingHandler}
              openCancelRecordingModal={() => {
                if (!isMR)
                  openCancelRecordingModal(selectedAssetRecordingInfo, selectedAssetRecordingInfo.assetToRecord);
              }}
            />
          )}
          {showWaysToOrder && (
            <WaysToOrderPrompt
              orderOptions={purchasePackages}
              onClosePrompt={closeWaysToOrderPrompt}
              onOptionClick={(purchasePackage) => {
                setShowWaysToOrder(false);
                openPurchaseModal(purchasePackage);
              }}
            />
          )}
          {selectedPurchasePackage && (
            <PurchaseModal
              itemMetadata={
                onDemandContent?.find((onDemandOption) => {
                  return onDemandOption?.id === selectedPurchasePackage?.contentId;
                })?.metadata
              }
              itemImage={getAVSPosterArtImage(contentMetadata.metadata, IMAGES.ASPECT_RATIOS.DIM_2x3)}
              closeModal={closePurchaseModal}
              purchaseItem={(selectedPackage, disallowPurchase) => {
                if (userProfile?.user?.profile?.profileData?.purchasePinEnabled === "Y") {
                  const modalContent = {
                    pinModalMode: PIN_MODAL_MODES.ACCESS,
                    pinModalType: PIN_MODAL_TYPES.PURCHASE,
                    title: translate("purchase_pin_enter"),
                    pinConfirmHandler: (pin) => {
                      return purchaseItem(selectedPackage, pin);
                    },
                    pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                      ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK_ERROR,
                      ACTION_VALUES.PURCHASE_PIN_UNLOCK,
                      WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_UNLOCK_ERROR
                    ),
                  };

                  dispatch(showModalPopup(MODAL_TYPES.PIN, modalContent));
                  trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK, getMediaNodeMetadata());
                  if (isConvivaAppTrackerEnabled) {
                    trackConvivaCustomEvent(
                      ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK,
                      getCustomContextMetadata(getMediaNodeMetadata(), userProfile, appProvider, isInHome)
                    );
                  }
                } else {
                  purchaseItem(selectedPackage, undefined, disallowPurchase);
                }
              }}
              purchasePackage={selectedPurchasePackage}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default DetailPage;
