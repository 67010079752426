export default Object.freeze({
  HAS_LOGGED_IN_BEFORE: "hasLoggedIn",
  PREV_IN_PANIC_STATE: "prevInPanicState",
  HAS_SEEN_GUIDE_ONBOARDING_TUTORIAL: "hasSeenGuideTutorial",
  HAS_LOGGED_IN_BEFORE_GUIDE: "hasLoggedInGuide",
  HAS_SEEN_ONBOARDING_TUTORIAL: "hasSeenTutorial",
  EDIT_PROFILES_PREVIOUS_PAGE: "editProfilesPreviousPage",
  ACTIVE_PROFILE_USER_ID: "activeProfileUserId",
  USER_PROPERTY_TYPE: "userPropertyType",
  USER_HAS_LOGGED_IN: "userLoggedIn",
  EPG_FETCH_TIMESTAMP: "epgFetchTS",
  EPG_SELECTED_CHANNEL_NUMBER: "epgSelectedChannelNumber",
  RESTARTABLE_CHANNELS: "restartable_channel_selected",
  AVS_REFRESH_TOKEN: "avs-refresh-token",
  USER_HAS_CLICK: "userHasClick",
  LANGUAGE: "i18nextLng",
  IS_FRESH_LOGIN: "isFreshLogin",
  SELECTED_CHANNEL: "selectedChannel",
  SELECTED_TIME: "epgSelectedTime",
  VIDEO_AUTH_TOKEN: "videoAuthToken",
  PLAYER_LOADED: "playerLoaded",
  DEVICE_ID: "deviceId",
  LOGIN_REDIRECT_URL: "loginRedirectUrl",
  FILTERS_SUFFIX: "_filters",
  SORT_OPTIONS_SUFFIX: "_sortOptions",
  FPID: "fpid",
  LAST_SELECTED_SWIMLANE_ID: "lastSelectedSwimlaneId",
  LAST_SCROLL_POSITION: "lastScrollPosition",
  LAST_VIEW_ALL_SCROLL_SWIMLANE_ID: "lastViewAllSelectedSwimlaneId",
  LAST_VIEW_ALL_SCROLL_POSITION: "lastViewAllScrollPosition",
  RECORDINGS_FILTER_VALUES: "recordingsFilterValues",
  CACHED_ACCOUNT_BRAND: "cachedAccountBrand",
  CANARY: "canary",
  USER_PROFILE: "userProfile",
  AUTH_USER_CONTENT: "authUserContent",
  EPG_SELECTED_DATE: "selectedDate",
  EPG_SELECTED_TIME: "selectedTime",
  EPG_SELECTED_TIME_FORMAT: "selectedFormat",
  GUIDE_RESTART: "guideRestart",
  HAS_SEEN_GUIDE_TOOLTIP: "hasSeenGuideTooltip",
  HAS_SEEN_FAV_GUIDE_TOOL_TIP: "hasSeenGuideToolTip",
  TOOL_TIP_SESSION_START: "startToolTipSession",
  HAS_SEEN_RESUME_TOOLTIP: "hasSeenResumeTooltip",
});
