import {
  getHomeStatus,
  createMediaNode,
  getProductDetails,
  formatAppProviderLanguage,
  getRecommendationProductDetails,
  getLink,
  getLinkName,
} from "../analytics/helpers";
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_EVENT_INFO_TYPES,
  WEB_ACTION_EVENT_NAMES,
  WEB_ACTION_EVENT_TYPES,
  ACTION_VALUES,
  EXTRA_METADATA_TYPES,
  ANALYTICS_ERROR_NAMES,
  MAPPED_CONTENT_TYPES,
  PROFILE_TYPES,
  NOTIFICATION_TYPES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  RECOMMENDATION_TYPES,
  SHOW_TYPES,
  UNKNOWN_VALUE,
  SPLUS_CUSTOMER_TYPES,
  WEB_ACTION_EVENT_VALUES,
} from "../constants/analytics";
import constants from "../constants";
import PlayerConstants from "../constants/player";
import swimlaneConstants from "../constants/swimlane";
import { getPurchaseTransactionType } from ".";
import { getSessionStorage } from "./sessionStorage";
import { PLAYBACK_TYPES } from "../constants/conviva";

const { TRANSACTION_TYPES, USER_PROPERTY_TYPES, USER_PROPERTY_NAMES, PROVINCE, LOGIN_BRANDS } = constants;
const { ASSET_TYPES, PLAYER_TYPE } = PlayerConstants;
const { SWIMLANE_TITLES } = swimlaneConstants;

/**
 * Transforms a string or object tracking value to lowercase and
 * replaces whitespace with underscore
 * @param {String|Object} value
 * @returns {String|Object}
 */
export function formatTrackingValue(value) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return value.trim().toLowerCase().replace(/\s/g, "_");
  }
  if (typeof value === "object") {
    const keys = Object.keys(value);
    if (keys.length > 0) {
      return keys.reduce((acc, key) => {
        acc[key] = formatTrackingValue(value[key]);
        return acc;
      }, {});
    }
  }

  if (typeof value === "number") {
    return value.toString();
  }

  return value;
}

/**
 * Generates a lowercase alphanumeric string with given length
 * @param {Number} length Length of random string
 * @returns {String}
 */
function generateRandomString(length = 20) {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
}

/**
 * Returns properties of analytics events
 * @param {String} eventType
 * @param {String|Object|Array} actionValue Action value specific to the event
 * @returns {Object}
 */
export function getActionProperties(eventType, actionValue = null) {
  const URL = window.location.href;
  const { linkName, linkPosition } = getLink();
  const formattedLinkName = formatTrackingValue(getLinkName(linkName));
  const formattedLinkPosition = formatTrackingValue(linkPosition);
  switch (eventType) {
    case ANALYTICS_EVENT_TYPES.GUIDE_SEARCH: {
      const { date, time, channels, categories, language, isSearchSuccessful } = actionValue;
      const formattedDateTime = `${formatTrackingValue(date)} ${formatTrackingValue(time)}`;
      const formattedFilters = `${formatTrackingValue(channels)} ${formatTrackingValue(categories)}`;
      const formattedLanguage = formatTrackingValue(formatAppProviderLanguage(language));
      return {
        eventType: ANALYTICS_EVENT_TYPES.SEARCH_REFINEMENT,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.SEARCH,
        name: WEB_ACTION_EVENT_NAMES.GUIDE_SEARCH,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        searchInfo: {
          search: {
            _telus: {
              instances: { value: 1 },
              refinementType: ACTION_VALUES.SEARCH_REFINEMENT_TYPE_FILTER,
              ...(isSearchSuccessful ? { successInstances: { value: 1 } } : { nullInstances: { value: 1 } }),
              refinementValue: `${formattedDateTime}:${formattedFilters}:${formattedLanguage}`,
            },
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.SWIMLANE_SEARCH: {
      const { content, categories, language, type, isSearchSuccessful } = actionValue;
      const formattedLanguage = formatAppProviderLanguage(language);
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.SEARCH,
        name: WEB_ACTION_EVENT_NAMES.SWIMLANE_SEARCH,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        searchInfo: {
          search: {
            _telus: {
              instances: { value: 1 },
              refinementType: type,
              ...(isSearchSuccessful ? { successInstances: { value: 1 } } : { nullInstances: { value: 1 } }),
              refinementValue: [content, categories, formattedLanguage].map(formatTrackingValue).join(":"),
            },
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.SETTINGS_INTERACT:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.SETTINGS_CLICK,
        name: actionValue,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toggle: 1,
          toggleName: actionValue,
        },
      };
    case ANALYTICS_EVENT_TYPES.EXIT_CLICK:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.EXIT,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: {
                value: 1,
                _telus: { exitLinkClick: 1 },
              },
              name: formatTrackingValue(actionValue.name),
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL: actionValue.URL,
              _telus: {
                exitLinkName: actionValue.URL,
              },
            },
          },
        },
      };
    case ANALYTICS_EVENT_TYPES.LOGIN_START:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.LOGIN_START,
        name: WEB_ACTION_EVENT_NAMES.LOGIN_START,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          userLoginStatus: "logged-out",
          userLoginStart: 1,
        },
      };
    case ANALYTICS_EVENT_TYPES.LOGIN_COMPLETE:
      const { isOutOfHome } = actionValue;
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.LOGIN_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.LOGIN_COMPLETE,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          userLoginComplete: 1,
          homeStatus: getHomeStatus(!isOutOfHome),
          userLoginStatus: "logged-in",
          ...formatTrackingValue(actionValue),
        },
      };
    case ANALYTICS_EVENT_TYPES.LOGOUT:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.LOGOUT,
        name: WEB_ACTION_EVENT_NAMES.LOGOUT,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          userLoginStatus: "logged-in",
          userLogout: 1,
        },
      };
    case ANALYTICS_EVENT_TYPES.REWIND:
    case ANALYTICS_EVENT_TYPES.FAST_FORWARD:
      const ffMediaNodes = createMediaNode(actionValue);
      if (ffMediaNodes) {
        ffMediaNodes._telus[eventType] = 1;
      }

      return {
        eventInfo:
          eventType === ANALYTICS_EVENT_TYPES.REWIND
            ? ANALYTICS_EVENT_INFO_TYPES.VIDEO_REWIND
            : ANALYTICS_EVENT_INFO_TYPES.VIDEO_FORWARD,
        eventDetails: {
          web: {
            webInteraction: {
              name: eventType,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
            },
          },
          media: ffMediaNodes,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_ENABLE_START,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_ENABLE_START,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_ENABLE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_DISABLE_START,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_DISABLE_START,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_DISABLE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_ENABLE_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_ENABLE_COMPLETE,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_ENABLE,
          ...(actionValue?.isWithToggle
            ? {
                toggle: 1,
                toggleName: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_ENABLE_COMPLETE,
              }
            : {}),
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_DISABLE_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_DISABLE_COMPLETE,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_DISABLE,
          toggle: 1,
          toggleName: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_DISABLE_COMPLETE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_START: {
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_FILTER_START,
        name: `parental_${formatTrackingValue(actionValue.rating)}_content_filter_start`,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PARENTAL_FILTER,
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_COMPLETE: {
      const filterEventName = `parental_${formatTrackingValue(actionValue.rating)}_content_filter_complete`;
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_FILTER_COMPLETE,
        name: filterEventName,
        URL,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PARENTAL_FILTER,
          toolSelections: formatTrackingValue(actionValue.rating),
          toggle: 1,
          toggleName: filterEventName,
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_ERROR:
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_ERROR:
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_ERROR:
    case ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_ERROR:
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR:
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_ERROR:
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_ERROR:
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_ERROR:
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK_ERROR:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP_ERROR:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START_ERROR:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE_ERROR:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_ERROR:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_ERROR:
    case ANALYTICS_EVENT_TYPES.PROFILE_EDIT_ERROR:
    case ANALYTICS_EVENT_TYPES.PROFILE_CREATE_ERROR:
      return {
        eventType: ANALYTICS_EVENT_TYPES.ERROR,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ERROR,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: {
                value: 1,
              },
              name: actionValue.name,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              _telus: {
                errorInstances: {
                  value: 1,
                },
                toolName: actionValue.toolName,
                errorType: actionValue.errorType ?? ANALYTICS_ERROR_NAMES.USER_ERROR,
                errorDetails: formatTrackingValue(actionValue.errorDetails),
                errorMessage: formatTrackingValue(actionValue.errorMessage),
                errorCode: formatTrackingValue(actionValue.errorCode),
              },
            },
          },
        },
      };
    case ANALYTICS_EVENT_TYPES.RESTART:
      const restartMediaNode = createMediaNode(actionValue);
      if (restartMediaNode) {
        restartMediaNode.customMetadata._telus = { restart: 1 };
      }
      return {
        eventType: ANALYTICS_EVENT_TYPES.RESTART,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.RESTART,
        eventDetails: {
          web: {
            webInteraction: {
              name: WEB_ACTION_EVENT_NAMES.RESTART,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
            },
          },
          media: restartMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.LOOKBACK:
      const lookbackMediaNode = createMediaNode(actionValue);
      if (lookbackMediaNode) {
        lookbackMediaNode.customMetadata = { lookback: 1 };
      }
      return {
        eventType: ANALYTICS_EVENT_TYPES.LOOKBACK,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.LOOKBACK,
        eventDetails: {
          web: {
            webInteraction: {
              name: WEB_ACTION_EVENT_NAMES.LOOKBACK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
            },
          },
          media: lookbackMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_RESET_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_RESET_START,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_RESET_START,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_RESET,
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_RESET_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_RESET_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_RESET_COMPLETE,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PARENTAL_PIN_RESET,
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_LOCK:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_CONTENT_LOCK,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_CONTENT_LOCK,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: { value: 1 },
              name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_LOCK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              _telus: { parentalLock: 1 },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_CONTENT_UNLOCK,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PARENTAL_PIN_CONTENT_UNLOCK,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: { value: 1 },
              name: WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_UNLOCK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              _telus: { parentalUnLock: 1 },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_ENABLE_START,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_ENABLE_START,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_ENABLE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_DISABLE_START,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_DISABLE_START,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_DISABLE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_ENABLE_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_ENABLE_COMPLETE,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_ENABLE,
          toggle: 1,
          toggleName: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_ENABLE_COMPLETE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_SET_UP_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_DISABLE_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_DISABLE_COMPLETE,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_DISABLE,
          toggle: 1,
          toggleName: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_DISABLE_COMPLETE,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_RESET_START,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_RESET_START,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_RESET_START,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageStart: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_RESET,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.PIN_RESET_COMPLETE,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_RESET_COMPLETE,
        name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_RESET_COMPLETE,
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          toolUsageComplete: 1,
          toolName: ACTION_VALUES.PURCHASE_PIN_RESET,
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_LOCK,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: { value: 1 },
              name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_LOCK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              _telus: { purchaseLock: 1 },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE_PIN_UNLOCK,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: { value: 1 },
              name: WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_UNLOCK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              _telus: { purchaseUnlock: 1 },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.PRODUCT_IMPRESSION:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PRODUCT_IMPRESSION,
        eventDetails: {
          commerce: { productImpressions: { value: 1 } },
          productListItems: [getProductDetails(actionValue)],
          media: createMediaNode(actionValue),
        },
      };
    case ANALYTICS_EVENT_TYPES.PROMO_CLICK: {
      const { _telus: customProductDetails, ...productDetails } = getProductDetails(actionValue);
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PROMO_CLICK,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: {
                value: 1,
              },
              name: WEB_ACTION_EVENT_NAMES.PROMO_CLICK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
            },
          },
          productListItems: [
            {
              ...productDetails,
              _telus: {
                ...customProductDetails,
                campaignClick: 1,
              },
            },
          ],
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.PRODUCT_DETAIL_VIEW:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PRODUCT_DETAIL_VIEW,
        eventDetails: {
          commerce: {
            productViews: {
              value: 1,
            },
            _telus: {
              customProductViews: {
                value: 1,
              },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.ADD_TO_CART: {
      const { asset, purchasePackage } = actionValue;
      const { _telus: customProductDetails, ...productDetails } = getProductDetails(asset);
      const price = purchasePackage?.notRecurring?.discountedPrice ?? purchasePackage?.notRecurring?.price;

      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ADD_TO_CART,
        eventDetails: {
          commerce: {
            productListAdds: {
              value: 1,
            },
            productListOpens: {
              value: 1,
            },
            checkouts: {
              value: 1,
            },
          },
          media: createMediaNode(asset),
          productListItems: [
            {
              ...productDetails,
              _telus: {
                ...customProductDetails,
                unitsAdded: 1,
                listPrice: {
                  amount: price,
                },
              },
            },
          ],
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.PURCHASE: {
      const { appProvider, asset, purchasePackage, purchaseDetails } = actionValue;
      const { _telus: customProductDetails, ...productDetails } = getProductDetails(asset);
      const purchaseId = generateRandomString(20);

      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.PURCHASE,
        eventDetails: {
          commerce: {
            purchases: {
              value: 1,
            },
            _telus: {
              purchase: {
                value: getPurchaseTransactionType(purchasePackage?.name) === TRANSACTION_TYPES.BUY ? 1 : 0,
              },
              rental: {
                value: getPurchaseTransactionType(purchasePackage?.name) === TRANSACTION_TYPES.RENT ? 1 : 0,
              },
              priceTotal: {
                amount: purchaseDetails.transactionPrice,
                currencyCode: purchaseDetails.currency,
              },
            },
            order: {
              purchaseID: purchaseId,
              currencyCode: purchaseDetails.currency,
              priceTotal: purchaseDetails.transactionPrice,
              _telus: {
                customPurchaseID: purchaseId,
              },
              payments: [
                {
                  transactionID: purchaseDetails.sequenceId,
                  paymentAmount: purchaseDetails.transactionPrice,
                  paymentType: formatTrackingValue(appProvider?.paymentMethod),
                  currencyCode: purchaseDetails.currency,
                  _telus: {
                    transactionID: purchaseDetails.sequenceId,
                  },
                },
              ],
            },
          },
          media: createMediaNode(asset),
          productListItems: [
            {
              ...productDetails,
              priceTotal: purchaseDetails.transactionPrice,
              quantity: 1,
              _telus: {
                ...customProductDetails,
                productType: formatTrackingValue(getPurchaseTransactionType(purchasePackage?.name)),
                listPrice: {
                  amount: purchaseDetails.transactionPrice,
                },
                paymentAmount: {
                  amount: purchaseDetails.transactionPrice,
                },
              },
            },
          ],
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.STILL_WATCHING:
      const stillWatchingMediaNode = createMediaNode(actionValue);
      if (stillWatchingMediaNode) {
        stillWatchingMediaNode.customMetadata = { stillWatching: 1 };
      }

      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.STILL_WATCHING,
        eventDetails: {
          web: {
            webInteraction: {
              name: WEB_ACTION_EVENT_NAMES.STILL_WATCHING,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
            },
          },
          media: stillWatchingMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.BOOKMARK:
      const bookmarkMediaNode = createMediaNode(actionValue);
      const notStillWatching = actionValue?.isNotStillWatching ? 1 : null;

      if (bookmarkMediaNode) {
        bookmarkMediaNode.customMetadata._telus = {
          bookmark: 1,
          playhead: actionValue?.getPlayheadCallback(),
          notStillWatching,
        };
      }

      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.BOOKMARK,
        eventDetails: {
          web: {
            webInteraction: {
              name: ANALYTICS_EVENT_TYPES.BOOKMARK,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
              _telus: {
                notStillWatching,
              },
            },
          },
          media: bookmarkMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.FAVOURITE:
    case ANALYTICS_EVENT_TYPES.UNFAVOURITE:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.FAVOURITE,
        eventDetails: {
          web: {
            webInteraction: {
              name: eventType,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
              _telus: {
                favourite: 1,
              },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.ON_NOW_START:
      const onNowMediaNode = createMediaNode(actionValue);
      if (onNowMediaNode) {
        onNowMediaNode._telus["onNowStarts"] = 1;
      }
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ON_NOW_START,
        eventDetails: {
          web: {
            webInteraction: {
              name: WEB_ACTION_EVENT_NAMES.ON_NOW,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
            },
          },
          media: onNowMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.UP_NEXT_START:
      const upNextMediaNode = createMediaNode(actionValue);
      if (upNextMediaNode) {
        upNextMediaNode._telus["upNextStarts"] = 1;
      }
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.UP_NEXT_START,
        eventDetails: {
          web: {
            webInteraction: {
              name: WEB_ACTION_EVENT_NAMES.UP_NEXT,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: {
                value: 1,
              },
            },
          },
          media: upNextMediaNode,
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.ERROR:
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ERROR,
        name: formatTrackingValue(actionValue?.name ?? UNKNOWN_VALUE),
        type: WEB_ACTION_EVENT_TYPES.OTHER,
        URL,
        _telus: {
          errorInstances: { value: 1 },
          errorType: formatTrackingValue(actionValue?.name ?? UNKNOWN_VALUE),
          errorDetails: formatTrackingValue(actionValue?.details),
          errorMessage: formatTrackingValue(actionValue?.message),
          errorCode: actionValue?.code,
        },
      };
    case ANALYTICS_EVENT_TYPES.GEO_BLOCKED_ERROR: {
      return {
        eventType: ANALYTICS_EVENT_TYPES.ERROR,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ERROR,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: {
                value: 1,
              },
              name: WEB_ACTION_EVENT_NAMES.GEO_BLOCKED_ERROR,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              _telus: {
                errorInstances: {
                  value: 1,
                },
                errorType: ANALYTICS_ERROR_NAMES.USER_ERROR,
                errorDetails: WEB_ACTION_EVENT_NAMES.GEO_BLOCKED_ERROR,
                errorMessage: formatTrackingValue(actionValue?.message),
                errorCode: formatTrackingValue(actionValue?.code),
              },
            },
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE: {
      const recordMediaNode = createMediaNode(actionValue);
      return {
        eventType,
        eventInfo: {
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP]: ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_STOP,
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START]: ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_START,
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE]: ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_DELETE,
        }[eventType],
        eventDetails: {
          web: {
            webInteraction: {
              name: {
                [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP]:
                  WEB_ACTION_EVENT_VALUES.VIDEO_RECORD_STOP +
                  (actionValue?.metadata?.seriesId ? "_series" : "_episode"),
                [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START]:
                  WEB_ACTION_EVENT_VALUES.VIDEO_RECORD_START +
                  (actionValue?.metadata?.seriesId ? "_series" : "_episode"),
                [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE]:
                  WEB_ACTION_EVENT_VALUES.VIDEO_RECORD_DELETE +
                  (actionValue?.metadata?.seriesId ? "_series" : "_episode"),
              }[eventType],
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                videoRecordingStop: eventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP ? 1 : 0,
                videoRecordingStart: eventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START ? 1 : 0,
                videoRecordingDelete: eventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE ? 1 : 0,
                ...(actionValue.toolSelections
                  ? {
                      toolUsageComplete: 1,
                      toolName: actionValue?.isSeriesEvent
                        ? ACTION_VALUES.VIDEO_RECORD_SERIES_EDIT
                        : ACTION_VALUES.VIDEO_RECORD_EPISODE_EDIT,
                    }
                  : {}),
              },
            },
          },
          _telus: {
            media: recordMediaNode,
            productListItems: [
              getProductDetails({
                ...actionValue,
                isRecordingAsset: true,
                videoName: recordMediaNode?._telus?.videoName,
              }),
            ],
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.MEDIA_STOP: {
      const stopMediaNodes = createMediaNode(actionValue?.media);
      const isRecording = actionValue?.extraData?.isRecording;

      stopMediaNodes.sessionDetails.timePlayedSeconds = actionValue?.extraData?.timePlayedSeconds;
      stopMediaNodes.states = actionValue?.videoStates;
      stopMediaNodes.sessionDetails.assetID =
        stopMediaNodes.sessionDetails.assetID === null ? actionValue?.extraData?.assetId : null;

      return {
        eventType: ANALYTICS_EVENT_TYPES.MEDIA_STOP,
        eventInfo: {
          [ANALYTICS_EVENT_TYPES.MEDIA_STOP]: ANALYTICS_EVENT_TYPES.MEDIA_STOP,
        }[eventType],
        eventDetails: {
          productListItems: [
            getProductDetails({
              ...actionValue,
              isRecordingAsset: isRecording,
              videoName: stopMediaNodes?.sessionDetails?.name,
            }),
          ],
          web: {
            webInteraction: {
              name: {
                [ANALYTICS_EVENT_TYPES.MEDIA_STOP]: ANALYTICS_EVENT_TYPES.MEDIA_STOP,
              }[eventType],
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                mediaStop: 1,
                linkPosition: formattedLinkPosition,
                linkName: formattedLinkName,
              },
            },
          },
          _telus: {
            media: stopMediaNodes,
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START:
      return {
        eventType: ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EDIT_START,
        eventInfo: {
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START]:
            ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_SERIES_EDIT_START,
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START]:
            ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
        }[eventType],
        eventDetails: {
          web: {
            webInteraction: {
              name: eventType,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                toolUsageStart: 1,
                toolName: {
                  [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START]: ACTION_VALUES.VIDEO_RECORD_SERIES_EDIT,
                  [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START]: ACTION_VALUES.VIDEO_RECORD_EPISODE_EDIT,
                }[eventType],
              },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE:
    case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE:
      return {
        eventType: ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EDIT_COMPLETE,
        eventInfo: {
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE]:
            ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE,
          [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE]:
            ANALYTICS_EVENT_INFO_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE,
        }[eventType],
        eventDetails: {
          web: {
            webInteraction: {
              name: eventType,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                toolUsageComplete: 1,
                toolSelections: formatTrackingValue(actionValue.toolSelections),
                toolName: {
                  [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE]: ACTION_VALUES.VIDEO_RECORD_SERIES_EDIT,
                  [ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE]: ACTION_VALUES.VIDEO_RECORD_EPISODE_EDIT,
                }[eventType],
              },
            },
          },
          media: createMediaNode(actionValue),
          productListItems: [getProductDetails(actionValue)],
        },
      };
    case WEB_ACTION_EVENT_NAMES.PROFILE_EDIT_COMPLETE:
    case WEB_ACTION_EVENT_NAMES.PROFILE_CREATE_COMPLETE: {
      return {
        eventType: ANALYTICS_EVENT_TYPES.TOOL_COMPLETE,
        eventInfo: {
          [WEB_ACTION_EVENT_NAMES.PROFILE_EDIT_COMPLETE]: ANALYTICS_EVENT_INFO_TYPES.PROFILE_EDIT_COMPLETE,
          [WEB_ACTION_EVENT_NAMES.PROFILE_CREATE_COMPLETE]: ANALYTICS_EVENT_INFO_TYPES.PROFILE_CREATE_COMPLETE,
        }[eventType],
        eventDetails: {
          web: {
            webInteraction: {
              name: eventType,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              linkClicks: { value: 1 },
              _telus: {
                toolUsageComplete: 1,
                toolName: {
                  [WEB_ACTION_EVENT_NAMES.PROFILE_EDIT_COMPLETE]: ACTION_VALUES.EDIT_PROFILE,
                  [WEB_ACTION_EVENT_NAMES.PROFILE_CREATE_COMPLETE]: ACTION_VALUES.ADD_PROFILE,
                }[eventType],
                ...(actionValue?.toolSelections ? { toolSelections: actionValue?.toolSelections?.join(",") } : {}),
              },
            },
          },
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS: {
      const notificationType = actionValue.notificationType || NOTIFICATION_TYPES.SUBSCRIPTION;
      const message = formatTrackingValue(actionValue.message);
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.NOTIFICATION_IMPRESSIONS,
        eventDetails: {
          web: {
            webInteraction: {
              name: `${notificationType}_impression_${message}`,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                notificationImpressions: 1,
                notification: `${notificationType}|toast|${message}`,
              },
            },
          },
          ...(actionValue?.media
            ? {
                media: createMediaNode(actionValue?.media),
                productListItems: [getProductDetails(actionValue?.media)],
              }
            : {}),
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.NOTIFICATION_DISMISSALS: {
      const notificationType = actionValue?.notificationType || NOTIFICATION_TYPES.SUBSCRIPTION;
      const message = formatTrackingValue(actionValue.message);
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.NOTIFICATION_DISMISSALS,
        eventDetails: {
          web: {
            webInteraction: {
              name: `${notificationType}_dismiss_${message}`,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
              linkClicks: { value: 1 },
              _telus: {
                notificationDismissals: 1,
                notification: `${notificationType}|toast|${message}`,
              },
            },
          },
          media: createMediaNode(actionValue?.media),
          productListItems: [getProductDetails(actionValue?.media)],
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.PLAYBACK_ERROR: {
      return {
        eventType: ANALYTICS_EVENT_TYPES.ERROR,
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.ERROR,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: {
                value: 1,
              },
              name: WEB_ACTION_EVENT_NAMES.PLAYBACK_ERROR,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              _telus: {
                errorInstances: { value: 1 },
                errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
                errorDetails: formatTrackingValue(actionValue?.errorDetails) || WEB_ACTION_EVENT_NAMES.PLAYBACK_ERROR,
                errorMessage: formatTrackingValue(actionValue?.errorMessage),
                errorCode: formatTrackingValue(actionValue?.errorCode),
              },
            },
          },
          productListItems: [getProductDetails(actionValue?.media)],
        },
      };
    }
    case ANALYTICS_EVENT_TYPES.RECOMMENDATION_IMPRESSIONS: {
      return {
        eventInfo: ANALYTICS_EVENT_INFO_TYPES.RECOMMENDATION_IMPRESSIONS,
        eventDetails: {
          web: {
            webInteraction: {
              linkClicks: { value: 1 },
              name: WEB_ACTION_EVENT_NAMES.RECOMMENDATION_IMPRESSIONS,
              type: WEB_ACTION_EVENT_TYPES.OTHER,
              URL,
            },
          },
          productListItems: actionValue?.assets?.length
            ? actionValue.assets.map((product, index) =>
                getRecommendationProductDetails(product, actionValue.startingIndex + index + 1, actionValue.title)
              )
            : [],
        },
      };
    }
    default:
      return null;
  }
}

/**
 * Returns a media object and product list object based on given asset
 * @param {Object} asset
 * @param {Boolean} isInternalCampaign an indicator, if yes the promo banner ad was set
 * @param {Boolean} isProductImpressionClicked an indicator, if yes the promo banner was clicked
 * @param {Object} recoInfo recommendation info
 * @returns {Object}
 */
export const getMediaObjects = (asset, isInternalCampaign, isProductImpressionClicked, recoInfo = {}) => {
  const { formattedLinkPosition, isRecoSwimlaneItemClicked, isRecoViewAllItemClicked } = recoInfo;
  const videoName = formatTrackingValue(asset?.title || asset?.metadata?.title);
  const positionInSwimlane = formattedLinkPosition?.split(":")[1] || null;
  const recommendationInfo =
    isRecoSwimlaneItemClicked || isRecoViewAllItemClicked
      ? {
          recoClick: 1,
          recoName: `${videoName}:${positionInSwimlane}`,
          recoLocation: `${isRecoSwimlaneItemClicked ? LINK_INFO.SWIMLANE : LINK_INFO.VIEW_ALL}:${
            SWIMLANE_TITLES.SIMILAR_ITEMS
          }:${positionInSwimlane}`,
        }
      : {};

  return {
    media: createMediaNode({ ...asset, isInternalCampaign, isProductImpressionClicked }),
    productListItems: asset
      ? [getProductDetails({ ...asset, isInternalCampaign, isProductImpressionClicked, recommendationInfo })]
      : [],
  };
};

/**
 * Returns a metadata object
 * @param {String} metadataType an event type
 * @param {Object} actionValue actionValue specific to the metadataType
 * @param {Boolean} isInternalCampaign an indicator, if yes the promo banner ad was set
 * @param {Boolean} isProductImpressionClicked an indicator, if yes the promo banner was clicked
 * @returns {Object}
 */
export const getExtraMetadata = (metadataType, actionValue, isInternalCampaign, isProductImpressionClicked) => {
  switch (metadataType) {
    case ANALYTICS_EVENT_TYPES.SEARCH: {
      const { searchTerm, searchResultCount } = actionValue;
      const isSearchSuccessful = searchResultCount > 0;
      return {
        search: {
          _telus: {
            instances: { value: 1 },
            term: formatTrackingValue(searchTerm),
            numberOfResults: searchResultCount,
            refinementType: ACTION_VALUES.SEARCH_TYPE_ORIGINAL,
            ...(isSearchSuccessful ? { successInstances: { value: 1 } } : { nullInstances: { value: 1 } }),
          },
        },
      };
    }
    case EXTRA_METADATA_TYPES.COMMERCE: {
      return {
        commerce: {
          productViews: { value: 1 },
          _telus: {
            customProductViews: { value: 1 },
          },
        },
      };
    }
    case EXTRA_METADATA_TYPES.PRODUCTS_IMPRESSION_VIEW: {
      const productLength = actionValue?.length;
      return {
        productListItems: productLength
          ? actionValue.map((product) => {
              const { _telus: customProductDetails, ...productDetails } = getProductDetails({
                ...product,
                isInternalCampaign,
                isProductImpressionClicked,
              });
              return {
                ...productDetails,
                _telus: {
                  ...customProductDetails,
                  campaignImpression: 1,
                },
              };
            })
          : [],
      };
    }
    case EXTRA_METADATA_TYPES.SEARCH_CLICK: {
      const searchTerm = getSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM);
      const lastTrackedSearchEvent = window.adobeDataLayer
        .slice()
        .reverse()
        .find((event) => !!event.xdm?.search);

      return {
        search: {
          _telus: {
            resultsInstances: { value: 1 },
            term: formatTrackingValue(searchTerm),
            numberOfResults: lastTrackedSearchEvent?.xdm?.search?._telus?.numberOfResults ?? null,
            refinementType: ACTION_VALUES.SEARCH_TYPE_ORIGINAL,
          },
        },
      };
    }
    case EXTRA_METADATA_TYPES.RECOMMENDATION_IMPRESSIONS: {
      return {
        productListItems: actionValue?.items?.length
          ? actionValue.items.map((product, index) =>
              getRecommendationProductDetails(product, index + 1, actionValue.title, true)
            )
          : [],
      };
    }
    default:
      return {};
  }
};

/**
 * Returns a webInteraction object
 * @param {String} linkName
 * @param {String} linkPosition
 * @param {Object} profileEvent
 * @param {Object} recommendationClickEvent
 * @returns {Object}
 */
export const getPageLoadWebInteraction = (linkName, linkPosition, profileEvent, recommendationClickEvent) => {
  const { isRecoSwimlaneItemClicked, isRecoViewAllItemClicked, ...recoInfo } = recommendationClickEvent;

  return {
    _telus: {
      linkName,
      linkPosition,
      ...(isRecoViewAllItemClicked || isRecoSwimlaneItemClicked ? recoInfo : {}),
      ...(profileEvent || {}),
    },
  };
};

/**
 * Get stream format, if no value found, set the value to be "HD"
 * @param {Boolean} isUHD
 * @param {Boolean} isHDR
 */
export const getStreamFormat = (isUHD, isHDR) => {
  if (isUHD) return "UHD";
  if (isHDR) return "HDR";
  return "HD";
};

/**
 * Gets rating detail
 * @param {String} criticscore
 * @param {String} fanScore
 */
export const getRatingDetail = (criticScore, fanScore) => {
  if (criticScore && fanScore) return `${criticScore}|${fanScore}`;
  return null;
};

/**
 * Gets mapped content type
 * @param {Boolean} isLive
 * @param {String} playbackType
 * @param {String} defaultContentType
 * @param {Boolean} isRecording
 * @returns {String} mappedContentType: live|tvod|svod|fvod|cpvr|lpvr|live-restart|live-lookback
 */
export const getMappedContentType = (isLive, playbackType, defaultContentType, isRecording = false, isFast = false) => {
  if (isFast) return MAPPED_CONTENT_TYPES.FAST;
  if (isRecording) return defaultContentType ?? MAPPED_CONTENT_TYPES.CPVR;
  if (isLive) return defaultContentType ?? MAPPED_CONTENT_TYPES.LIVE;
  const isTrailer = playbackType === ASSET_TYPES.TRAILER;
  if (isTrailer) return MAPPED_CONTENT_TYPES.SVOD;
  return formatTrackingValue(defaultContentType);
};

/**
 * Gets a mapped user profile type
 * @param {Object} profileData
 * @returns {String} mappedUserProfileType: main|kid|standard
 */
export const getMappedUserProfileType = (profileData) => {
  if (!profileData) return null;
  if (profileData.isMasterAccount === "Y") return PROFILE_TYPES.MAIN;
  if (profileData.kidsProfile) return PROFILE_TYPES.KID;

  return PROFILE_TYPES.STANDARD;
};

/**
 * Gets a recommendation metadata
 * @param {Boolean} isRecoSwimlaneItemClicked
 * @param {Boolean} isRecoViewAllItemClicked
 * @param {Array} extraMetadataList
 * @param {String} linkPosition
 * @returns {String} linkPosition
 */
export const getRecommendationMetadata = (
  isRecoSwimlaneItemClicked,
  isRecoViewAllItemClicked,
  extraMetadataList,
  linkPosition
) => {
  if (!(isRecoSwimlaneItemClicked || isRecoViewAllItemClicked) || !Array.isArray(extraMetadataList))
    return { recoClicked: null, isRecoSwimlaneItemClicked, isRecoViewAllItemClicked };

  const mediaContent = extraMetadataList.find(
    (extraMetadata) => extraMetadata.type === EXTRA_METADATA_TYPES.MEDIA_CONTENT
  );
  const videoName = formatTrackingValue(mediaContent?.asset?.title || mediaContent?.asset?.metadata?.title);

  return {
    recoClicked: `${videoName}:${linkPosition?.split(":")[1] || null}`,
    isRecoViewAllItemClicked,
    isRecoSwimlaneItemClicked,
  };
};

/**
 * Checks if linkName is a recommendation type
 * @param {String} linkName
 * @returns {Boolean}
 */
export const isRecommendation = (linkName) => Object.values(RECOMMENDATION_TYPES).includes(linkName);

/**
 * Gets a mapped show type
 * @param {string} uaGroupType
 * @param {string} playbackType
 * @returns {String|null} mappedShowType: trailer|tv_shows|movies|null
 */
export function getMappedShowType(uaGroupType, playbackType) {
  if (String(playbackType).toLowerCase() === PLAYBACK_TYPES.TRAILER) return SHOW_TYPES.TRAILER;

  const groupType = String(uaGroupType).toLowerCase();
  if (groupType === "tvshow") return SHOW_TYPES.TV_SHOWS;
  else if (groupType === "movie") return SHOW_TYPES.MOVIES;

  return null;
}

/**
 * Gets a mapped media content type
 * @param {Boolean} isItemLive
 * @param {string} contentType
 * @returns {String|null} mappedMediaContentType: linear|vod|pvr
 */
export function getMappedMediaContentType(isItemLive, contentType) {
  if (isItemLive) return PLAYER_TYPE.LINEAR;
  if (String(contentType).toLowerCase() === "recording") return "pvr";
  if (typeof isItemLive === "boolean") return PLAYER_TYPE.VOD;

  return null;
}

/**
 * Gets customer type based on the user profile data
 * @param {Object} userProfile
 * @returns {String} customerType
 */

export const getCustomerType = (userProfile, isTechTrial) => {
  // see customer type details in https://telusvideoservices.atlassian.net/browse/TCDWC-3281
  const userAccountType = userProfile?.user?.profile?.profileData?.customAttributes?.find(
    (item) => item.attributeName === "USERACCOUNTTYPE"
  )?.attributeValue;
  const userPropertyType = userProfile?.user?.profile?.profileData?.userPropertyType;
  const userPropertyName = userProfile?.user?.profile?.profileData?.properties?.[0]?.propertyName;
  const userGeoStateName = userProfile?.user?.profile?.profileData?.geoStateName;
  const userAccountBrand = userProfile?.user?.profile?.profileData?.customAttributes?.find(
    (item) => item.attributeName === "BRAND"
  )?.attributeValue;
  if (userAccountType && !userPropertyType) {
    return userAccountType.toLowerCase();
  }
  if (isTechTrial) {
    return USER_PROPERTY_TYPES.TECH_TRIAL;
  }
  if (
    userPropertyType &&
    userPropertyType === USER_PROPERTY_TYPES.OPTIK_AVS &&
    userPropertyName?.toUpperCase() === USER_PROPERTY_NAMES.PIK &&
    userGeoStateName !== PROVINCE.QUEBEC
  ) {
    return USER_PROPERTY_TYPES.PIK;
  }
  if (
    userPropertyType &&
    userPropertyType === USER_PROPERTY_TYPES.OPTIK_AVS &&
    userPropertyName?.toUpperCase() === USER_PROPERTY_NAMES.OPTIK &&
    userGeoStateName !== PROVINCE.QUEBEC
  ) {
    return USER_PROPERTY_TYPES.OPTIK_AVS;
  }
  if (
    userPropertyType &&
    userPropertyType !== USER_PROPERTY_TYPES.OPTIK_AVS &&
    userPropertyName?.toUpperCase() === USER_PROPERTY_NAMES.OPTIK &&
    userGeoStateName !== PROVINCE.QUEBEC
  ) {
    return USER_PROPERTY_TYPES.MEDIA_ROOM;
  }
  if (
    userPropertyType &&
    userPropertyType === USER_PROPERTY_TYPES.OPTIK_AVS &&
    userPropertyName?.toUpperCase() === USER_PROPERTY_NAMES.OPTIK &&
    userGeoStateName === PROVINCE.QUEBEC
  ) {
    return USER_PROPERTY_TYPES.QUEBEC_TRIAL;
  }
  if (
    userPropertyType &&
    userPropertyType !== USER_PROPERTY_TYPES.OPTIK_AVS &&
    userPropertyName?.toUpperCase() === USER_PROPERTY_NAMES.OPTIK &&
    userGeoStateName === PROVINCE.QUEBEC
  ) {
    return USER_PROPERTY_TYPES.QUEBEC_NULL;
  }
  if (userAccountBrand?.toLowerCase() === LOGIN_BRANDS.TELUS) {
    return SPLUS_CUSTOMER_TYPES.TELUS;
  }
  if (userAccountBrand?.toLowerCase() === LOGIN_BRANDS.KOODO) {
    return SPLUS_CUSTOMER_TYPES.KOODO;
  }

  return null;
};
