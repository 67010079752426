import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import PlayerConstants from "../../shared/constants/player";
import { formatSecondsToTimeDisplay, getProgress } from "../../shared/utils/playerUtils";
import { isTimestampFromPastDay } from "../../shared/utils/playbackHelpers";
import {
  ProgressWrap,
  ProgressBarStyled,
  ProgressWrapActive,
  ProgressIndicator,
  ProgressBufferIndicator,
  KnobTooltip,
  HoverIndicator,
  ProgressMarker,
  Knob,
  TimeLabels,
} from "./ProgressBar.styles";

const { LIVE, RECORDING } = PlayerConstants.PLAYER_TYPE;
const { PLAYBACK_FORWARD_BUFFER } = PlayerConstants;

/**
 * @component
 * Progress bar indicator displaying progression of the video
 */
const ProgressBar = (props) => {
  let {
    progress,
    disabled,
    onProgressClick,
    startTimeEpoch,
    recordingAssetRemainingTime,
    remainingTime,
    userRecordingStopTime,
    isRecording,
    playerMgr,
    playerType,
    elapsedTime,
    duration,
    leftMargin,
    bufferProgress,
    showLiveProgress,
    currentPlaybackTime,
    endTime,
    startTime,
    showToastNotification,
    updateLandTime,
    isTimeShiftAllowed,
    pauseLiveProperties,
    isOnDemandType,
    checkIfTimeShiftAllowed,
    isRestartedStream,
    isLookbackStream,
    isTimeShifting,
    isSeeking,
    isRestartTrickPlayAllowed,
    isPlayerControlsDisable,
  } = props;
  const { t: translate } = useTranslation();
  const [progressStyles, setProgressStyle] = useState(0);
  const [isDragged, setIsDragged] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(progress);
  const [zoetropePosition, setZoetropePosition] = useState(progress);
  const [tooltipTime, setTooltipTime] = useState("00:00");
  const [hasThumbnail, setHasThumbnail] = useState(false);
  const progressContainer = useRef(null);
  const progressIndicator = useRef(null);
  const newLeftWidth = useRef(0);
  const bufferIndicator = useRef(null);
  const leftMarginStyle = useRef(0);
  const newPosition = useRef(null);
  const thumbnail = useRef(null);

  let x = 0;
  let leftWidth = 0;
  let wrapperWidth = 0;
  const zoetropeUpperlimit = 88.5;
  const zoetropeLowerlimit = 11.5;

  const leftMarginWidth = (leftMargin / 100) * progressContainer.current?.offsetWidth;
  const durationLive = endTime / 1000 - startTime / 1000;
  const secondsPerPixel = durationLive / progressContainer.current?.offsetWidth;
  const initialLandingTimestamp =
    isTimeShiftAllowed && showLiveProgress ? startTime / 1000 + leftMarginWidth * secondsPerPixel : null;
  const liveThresholdTimestamp =
    isTimeShiftAllowed && pauseLiveProperties?.bufferThresholdMins
      ? initialLandingTimestamp + pauseLiveProperties.bufferThresholdMins * 60
      : null;
  const liveThresholdWidth = liveThresholdTimestamp
    ? (liveThresholdTimestamp - startTime / 1000) / secondsPerPixel
    : null;
  const bufferIndicatorWidth = bufferIndicator.current?.offsetWidth;

  useEffect(() => {
    if (leftMargin >= 0) {
      leftMarginStyle.current = leftMargin;
    }
  }, [leftMargin]);

  useEffect(() => {
    if (
      !isRestartedStream &&
      !isLookbackStream &&
      liveThresholdWidth > 0 &&
      bufferIndicatorWidth >= 0 &&
      leftMarginWidth >= 0 &&
      bufferIndicatorWidth + leftMarginWidth > liveThresholdWidth
    ) {
      updateLandTime();
    }
  }, [bufferIndicatorWidth, leftMarginWidth, liveThresholdWidth, updateLandTime, isRestartedStream, isLookbackStream]);

  useEffect(() => {
    if (!isTimeShifting && !isSeeking) {
      setIsUpdating(false);
    }
  }, [isTimeShifting, isSeeking]);

  useEffect(() => {
    if (!isDragged && !playerMgr?.isStalled()) {
      if (playerType === LIVE && !isLookbackStream) {
        if (!isUpdating) {
          setProgressStyle(() => progress);
        }
      } else {
        setProgressStyle(() => progress);
      }
    }

    if (!isHovered && !isDragged) {
      setTooltipPosition(() => progressStyles + leftMarginStyle.current);
      if (currentPlaybackTime > 0 && showLiveProgress && !isLookbackStream && playerType !== RECORDING) {
        if (isTimestampFromPastDay(currentPlaybackTime)) {
          setTooltipTime(
            moment(startTime + progressIndicator.current?.offsetWidth * secondsPerPixel * 1000).format("LTS")
          );
        } else if (!isUpdating) {
          setTooltipTime(moment(currentPlaybackTime).format("LTS"));
        }
      } else {
        setTooltipTime(elapsedTime);
      }
    }
  }, [
    progress,
    isDragged,
    playerType,
    elapsedTime,
    isHovered,
    currentPlaybackTime,
    showLiveProgress,
    leftMargin,
    playerMgr,
    secondsPerPixel,
    startTime,
    isLookbackStream,
    isUpdating,
    progressStyles,
  ]);

  const getThumbnail = (progress) => {
    thumbnail.current = playerMgr.getThumbnail(progress);
  };

  const onKnobDrag = (event) => {
    x = event.clientX - leftMarginStyle.current;
    leftWidth = progressIndicator.current.offsetWidth;
    progressContainer.current.addEventListener("mousemove", onKnobMove);
    progressContainer.current.addEventListener("mouseup", onKnobStop);
    progressContainer.current.addEventListener("mouseleave", onKnobStop);
  };

  const onKnobMove = (event) => {
    setIsDragged(true);
    if (playerType === LIVE && !isLookbackStream) {
      if (event.clientX < leftMarginWidth + PLAYBACK_FORWARD_BUFFER) {
        newPosition.current = Math.round(leftMarginWidth + PLAYBACK_FORWARD_BUFFER);
      } else if (event.clientX > bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER) {
        newPosition.current = Math.round(
          bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER
        );
      } else {
        newPosition.current = event.clientX;
      }
    } else {
      newPosition.current = event.clientX;
    }
    const dx = newPosition.current - leftMarginStyle.current - x;
    wrapperWidth = progressContainer.current.offsetWidth - leftMarginStyle.current;
    newLeftWidth.current = ((leftWidth + dx) * 100) / wrapperWidth;
    newLeftWidth.current = Math.max(newLeftWidth.current, 0);
    newLeftWidth.current = Math.min(newLeftWidth.current, 100);
    setProgressStyle(newLeftWidth.current);
  };

  const onKnobStop = (event) => {
    if (
      playerType === LIVE &&
      !isLookbackStream &&
      newPosition.current <= Math.round(leftMarginWidth + PLAYBACK_FORWARD_BUFFER)
    ) {
      showToastNotification(translate("beginning_of_available_stream_reached"));
    }
    if (
      playerType === LIVE &&
      !isLookbackStream &&
      newPosition.current >=
        Math.round(bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER)
    ) {
      showToastNotification(translate("live_position_reached"));
    }
    let progress;
    if (playerType === LIVE && playerMgr?.isLive()) {
      const duration = endTime / 1000 - startTime / 1000;
      const secondsPerPixel = duration / progressContainer.current.offsetWidth;
      progress = Math.floor(startTime / 1000 + (newPosition.current - PLAYBACK_FORWARD_BUFFER) * secondsPerPixel);
    } else {
      progress = setProgress(event);
    }
    if (!disabled) {
      onProgressClick(progress);
    }
    setIsDragged(false);
    progressContainer.current.removeEventListener("mousemove", onKnobMove);
    progressContainer.current.removeEventListener("mouseup", onKnobStop);
    progressContainer.current.removeEventListener("mouseleave", onKnobStop);
  };

  const handleClick = (event) => {
    let newProgress;
    if ((checkIfTimeShiftAllowed() || isRestartedStream) && playerMgr?.isLive()) {
      const duration = endTime / 1000 - startTime / 1000;
      const secondsPerPixel = duration / progressContainer.current.offsetWidth;
      const maxTimeShift = playerMgr?.getMaxTimeShift();
      const liveEdgeTimeStamp = startTime / 1000 + bufferIndicatorWidth * secondsPerPixel;
      const updatedProgress = setProgress(event);
      if (isRestartedStream && updatedProgress >= progress) {
        if (isRestartTrickPlayAllowed) {
          setProgressStyle(updatedProgress - leftMarginStyle.current);
          setIsUpdating(true);
        }
      } else {
        setProgressStyle(updatedProgress - leftMarginStyle.current);
        setIsUpdating(true);
      }
      newProgress = Math.floor(startTime / 1000 + (event.clientX - PLAYBACK_FORWARD_BUFFER) * secondsPerPixel);
      if (newProgress - liveEdgeTimeStamp < maxTimeShift) {
        newProgress = updatedProgress;
      }
    } else {
      newProgress = setProgress(event);
      if (isRestartedStream) {
        if (newProgress > progress) {
          if (isRestartTrickPlayAllowed) {
            setProgressStyle(newProgress);
            setIsUpdating(true);
          }
        } else {
          setProgressStyle(newProgress);
          setIsUpdating(true);
        }
      }
    }
    if (!disabled && newProgress) {
      if (playerType === RECORDING && playerMgr?.isLive()) {
        setIsUpdating(true);
      }
      onProgressClick(newProgress);
    }
  };

  const setProgress = (event) => {
    let newPosition;
    if (playerType === LIVE && playerMgr?.isLive()) {
      if (event.clientX < leftMarginWidth + PLAYBACK_FORWARD_BUFFER) {
        newPosition = Math.round(leftMarginWidth + PLAYBACK_FORWARD_BUFFER);
      } else if (event.clientX > bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER) {
        newPosition = Math.round(bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER);
      } else {
        newPosition = event.clientX;
      }
    } else {
      newPosition = event.clientX;
    }
    wrapperWidth = progressContainer.current.offsetWidth;
    let progress;
    if (newPosition - PLAYBACK_FORWARD_BUFFER >= 0) {
      progress = getProgress(newPosition - PLAYBACK_FORWARD_BUFFER, wrapperWidth);
    } else {
      progress = 0;
    }
    return progress;
  };

  const progressHoverIn = (event) => {
    if (progressContainer.current) {
      if (isOnDemandType || isLookbackStream) {
        setTooltipPosition(setProgress(event));
        getTooltipTimeVOD(setProgress(event));
      } else if (
        event.clientX > leftMarginWidth + PLAYBACK_FORWARD_BUFFER &&
        event.clientX < bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER
      ) {
        setTooltipPosition(setProgress(event));
        getTooltipTimeLive(event);
      }

      // Calculate zoetrope position in relation to progress percentage
      let zoetropePos = setProgress(event);
      zoetropePos = zoetropePos < zoetropeLowerlimit ? zoetropeLowerlimit : zoetropePos;

      if (zoetropePos > zoetropeUpperlimit) {
        zoetropePos = zoetropeUpperlimit;
      }

      setZoetropePosition(zoetropePos);

      setIsHovered(true);
      progressContainer.current.addEventListener("mouseleave", progressHoverOut);
    }
  };

  const progressHoverOut = (event) => {
    setIsHovered(false);
    progressContainer.current.removeEventListener("mouseleave", progressHoverOut);
  };

  const processThumbnail = () => {
    if (thumbnail.current) {
      return true;
    }
    return false;
  };

  const getTooltipTimeVOD = (progress) => {
    let findTooltipTime;
    let videoPos = Math.floor((duration / 100) * progress);
    findTooltipTime = !Number.isNaN(videoPos)
      ? videoPos <= duration
        ? formatSecondsToTimeDisplay(videoPos)
        : formatSecondsToTimeDisplay(duration)
      : formatSecondsToTimeDisplay(0);
    setTooltipTime(findTooltipTime);
    getThumbnail(videoPos);
    setHasThumbnail(processThumbnail());
  };

  const getTooltipTimeLive = (event) => {
    let newPosition;
    newPosition = Math.max(event.clientX, leftMarginWidth + PLAYBACK_FORWARD_BUFFER);
    newPosition = Math.min(
      event.clientX,
      bufferIndicator.current?.offsetWidth + leftMarginWidth + PLAYBACK_FORWARD_BUFFER
    );
    const duration = endTime / 1000 - startTime / 1000;
    const secondsPerPixel = duration / progressContainer.current.offsetWidth;
    let findTooltipTime;
    let videoPos = Math.floor(startTime / 1000 + (newPosition - PLAYBACK_FORWARD_BUFFER) * secondsPerPixel);
    findTooltipTime = !Number.isNaN(videoPos)
      ? videoPos * 1000 <= endTime
        ? moment(videoPos * 1000).format("LTS")
        : moment(endTime).format("LTS")
      : formatSecondsToTimeDisplay(0);
    setTooltipTime(findTooltipTime);
  };

  return (
    <>
      <ProgressWrap ref={progressContainer}>
        <ProgressBarStyled style={{ width: leftMarginStyle.current + "%" }} />
        <ProgressWrapActive
          style={isOnDemandType || isLookbackStream ? { width: progressStyles + "%" } : { width: bufferProgress + "%" }}
          onClick={!isPlayerControlsDisable && !playerMgr?.isStalled() ? handleClick : () => {}}
          onMouseMove={!playerMgr?.isStalled() ? progressHoverIn : () => {}}
        >
          {isOnDemandType || showLiveProgress ? (
            <>
              {isHovered && hasThumbnail ? (
                <div
                  style={{
                    position: "absolute",
                    left: zoetropePosition + "%",
                    bottom: "230%",
                  }}
                >
                  <div
                    style={{
                      height: (window.outerHeight / window.screen.availHeight) * thumbnail.current?.height,
                      width: (window.outerWidth / window.screen.availWidth) * thumbnail.current?.width,
                      left: "-" + 50 + "%",
                      overflow: "hidden",
                      border: "solid 2px",
                      position: "relative",
                      radius: "4px",
                      gap: "0px",
                      "border-radius": "4px",
                      opacity: "0px",
                    }}
                  >
                    <img
                      src={thumbnail.current?.url}
                      style={{
                        position: "relative",
                        left: "-" + thumbnail.current?.x + "px",
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {tooltipTime ? <KnobTooltip style={{ left: tooltipPosition + "%" }}>{tooltipTime}</KnobTooltip> : null}
              {isHovered ? (
                <HoverIndicator
                  style={isOnDemandType ? { left: tooltipPosition - 0.3 + "%" } : { left: tooltipPosition + "%" }}
                />
              ) : null}
              <ProgressIndicator
                className={
                  isOnDemandType || checkIfTimeShiftAllowed() || isRestartedStream || isLookbackStream
                    ? "hover-styles"
                    : ""
                }
                style={{ width: progressStyles + "%" }}
                ref={progressIndicator}
              />
              <ProgressBufferIndicator
                className={isOnDemandType || checkIfTimeShiftAllowed() || isRestartedStream ? "hover-styles" : ""}
                ref={bufferIndicator}
                style={{ width: bufferProgress + "%" }}
              />
              {!isPlayerControlsDisable &&
              (isOnDemandType || checkIfTimeShiftAllowed() || isRestartedStream || isLookbackStream) ? (
                <ProgressMarker style={{ left: `${leftMarginStyle.current + progressStyles}%` }}>
                  <Knob onMouseDown={onKnobDrag} />
                </ProgressMarker>
              ) : null}
            </>
          ) : null}
        </ProgressWrapActive>
        <ProgressBarStyled
          className={isOnDemandType || isLookbackStream ? "hover-styles" : ""}
          style={{ flexGrow: "1" }}
          onClick={
            !isPlayerControlsDisable && (isOnDemandType || isLookbackStream) && !playerMgr?.isStalled()
              ? handleClick
              : () => {}
          }
          onMouseMove={
            !isPlayerControlsDisable && (isOnDemandType || isLookbackStream) && !playerMgr?.isStalled()
              ? progressHoverIn
              : () => {}
          }
        />
      </ProgressWrap>
      <TimeLabels>
        <span className="current">
          {!isRecording && !isLookbackStream && startTimeEpoch && !Number.isNaN(startTimeEpoch)
            ? moment(startTimeEpoch).format("LT")
            : ""}
        </span>
        <span className="end">
          {!Number.isNaN(recordingAssetRemainingTime ? recordingAssetRemainingTime : remainingTime) &&
          (recordingAssetRemainingTime ? recordingAssetRemainingTime : remainingTime) > 0
            ? formatSecondsToTimeDisplay(recordingAssetRemainingTime ? recordingAssetRemainingTime : remainingTime)
            : userRecordingStopTime
            ? moment(userRecordingStopTime).format("LT")
            : !isLookbackStream && endTime && !Number.isNaN(endTime) && endTime > 0
            ? moment(endTime).format("LT")
            : ""}
        </span>
      </TimeLabels>
    </>
  );
};

ProgressBar.propTypes = {
  onProgressClick: PropTypes.func,
  disabled: PropTypes.bool,
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  buffer: PropTypes.number,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  startTimeEpoch: PropTypes.number,
  recordingAssetRemainingTime: PropTypes.number,
  remainingTime: PropTypes.number,
  userRecordingStopTime: PropTypes.number,
  isRecording: PropTypes.bool,
  isLookbackStream: PropTypes.bool,
  endTime: PropTypes.number,
};

ProgressBar.defaultProps = {
  onProgressClick: () => {},
  disabled: false,
  startTimeEpoch: 0,
  recordingAssetRemainingTime: 0,
  remainingTime: 0,
  userRecordingStopTime: 0,
  isRecording: false,
  isLookbackStream: false,
  endTime: 0,
};

export default ProgressBar;
