/**
 * load core modules
 */
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
/* load core modules end */

/**
 * load custom hooks
 */
import { useReducers } from "./useReducer";
/* load custom hooks end */

/**
 * load actions
 */
import { loadMovieDetailItems } from "../../pages/MovieDetailPage/state/actions";
import { loadEpisodeDetailItems } from "../../pages/EpisodeDetailPage/state/actions";
import { loadTVShowDetailItems } from "../../pages/SeriesDetailPage/state/actions";
import { loadContentItemDetailPageItems } from "../../pages/ContentItemDetailsPage/state/actions";
import { showModalPopup } from "../../App/state/actions";
/* load actions end */

import useCancelTokenSource from "./useCancelTokenSource";

/**
 * load constants
 */
import constants from "../../shared/constants/index";
/* load constants end */

/**
 * load utilities
 */
import { checkUnifiedAsset, addQueryParamsToString, getPcLevelRestriction, findContainerByLayout } from "../utils";
import { useHistory } from "react-router-dom";
/* load utilities end */

/** declare/destructure constants */
const { CONTENT_ITEM_TYPES, CONTAINER_TYPES, CONTAINER_LAYOUTS, REDUCER_TYPE, MODAL_TYPES } = constants;

/**
 * Custom hook for composing detail pages
 */
export default function useDetailsPageCompositor(contentItemType, isTeamsPage = false, isSportSeries = false) {
  const [contentMetadata, setContentMetadata] = useState(null);

  const isUnifiedAsset = useRef(true);

  const cancelTokenSource = useCancelTokenSource();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    provider: appProvider,
    featureToggles,
    userProfile,
    channelMapInfo,
    shouldUseContentItemDetailsPage,
  } = useReducers(REDUCER_TYPE.APP);
  const { isRecommendationMLTEnabled, isParentalPINEnabled, isUserProfilesEnabled } = featureToggles;

  const pcLevel = useMemo(
    () => getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled),
    [isParentalPINEnabled, isUserProfilesEnabled, userProfile]
  );
  const isUserLoggedIn = !!userProfile?.isLoggedIn;

  const detailsPageConfig = useMemo(() => {
    const config = {};
    switch (contentItemType) {
      case CONTENT_ITEM_TYPES.movie:
        config.detailsPageActionMethod = shouldUseContentItemDetailsPage
          ? loadContentItemDetailPageItems
          : loadMovieDetailItems;
        config.contentItemLayout = CONTAINER_LAYOUTS.CONTENT_ITEM;
        config.reducerType = shouldUseContentItemDetailsPage ? REDUCER_TYPE.CONTENT_ITEM : REDUCER_TYPE.MOVIE_DETAILS;
        break;
      case CONTENT_ITEM_TYPES.episode:
        config.detailsPageActionMethod = shouldUseContentItemDetailsPage
          ? loadContentItemDetailPageItems
          : loadEpisodeDetailItems;
        config.contentItemLayout = CONTAINER_LAYOUTS.CONTENT_ITEM;
        config.reducerType = shouldUseContentItemDetailsPage ? REDUCER_TYPE.CONTENT_ITEM : REDUCER_TYPE.EPISODE_DETAIL;
        break;
      case CONTENT_ITEM_TYPES.series:
        config.detailsPageActionMethod = loadTVShowDetailItems;
        config.contentItemLayout = isTeamsPage ? CONTAINER_LAYOUTS.CONTENT_ITEM : CONTAINER_LAYOUTS.BUNDLE_ITEM;
        config.reducerType = REDUCER_TYPE.SERIES_DETAILS;
        break;
      default:
        break;
    }
    return config;
  }, [contentItemType, shouldUseContentItemDetailsPage, isTeamsPage]);

  const { detailsPageActionMethod, contentItemLayout } = detailsPageConfig;
  const { containers, content, contentUserData } = useReducers(detailsPageConfig.reducerType);

  // load metadata for the details page
  useEffect(() => {
    // TODO: While refactoring seriesDetailsPage check the render method as the previous containers are coming up for the current page.
    if (containers) {
      const contentDetailContainer = findContainerByLayout(containers, CONTAINER_LAYOUTS.CONTENT_DETAIL);
      contentDetailContainer &&
        dispatch(
          detailsPageActionMethod(appProvider, contentDetailContainer.retrieveItems?.uri, CONTAINER_TYPES.CONTENT)
        );

      const contentItemContainer = findContainerByLayout(containers, contentItemLayout);
      if (contentItemContainer) {
        isUnifiedAsset.current = checkUnifiedAsset(contentItemContainer.metadata);
        if (isUnifiedAsset.current) {
          setContentMetadata(contentItemContainer);
        } else {
          history.push("/");
          dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
        }
      }
      const contentTeamContainer = !isTeamsPage && findContainerByLayout(containers, CONTAINER_LAYOUTS.LIST_OF_TEAMS);
      if (contentTeamContainer) {
        dispatch(
          detailsPageActionMethod(appProvider, contentTeamContainer.retrieveItems?.uri, CONTAINER_TYPES.LIST_OF_TEAMS)
        );
      }
      const recentAiringsContainer =
        (isTeamsPage || isSportSeries) && findContainerByLayout(containers, CONTAINER_LAYOUTS.RECENT_AIRINGS);
      if (recentAiringsContainer) {
        let recentAiringsUri = recentAiringsContainer.retrieveItems?.uri;
        if (recentAiringsUri) {
          if (isSportSeries) {
            recentAiringsUri = !recentAiringsUri.includes("teamUmId") && recentAiringsUri;
          } else if (isTeamsPage) {
            recentAiringsUri = recentAiringsUri.includes("teamUmId") && recentAiringsUri;
          }
        }
        if (appProvider?.channelMapID && recentAiringsUri) {
          recentAiringsUri = `${recentAiringsUri}&filter_contentType=VOD&filter_regionId=${appProvider.channelMapID}`;
        }
        dispatch(detailsPageActionMethod(appProvider, recentAiringsUri, CONTAINER_TYPES.RECENT_AIRINGS));
      }
      const liveGameContainer =
        (isSportSeries || isTeamsPage) && findContainerByLayout(containers, CONTAINER_LAYOUTS.GAMES);
      if (liveGameContainer) {
        dispatch(
          detailsPageActionMethod(
            appProvider,
            userProfile?.isLoggedIn
              ? liveGameContainer.retrieveItems?.uri.replace("DETAIL", "USERDATA")
              : liveGameContainer.retrieveItems?.uri,
            CONTAINER_TYPES.GAMES
          )
        );
      }

      // load similar items for the details page from recommendation engine(if enabled)
      if (isUnifiedAsset.current) {
        if (isRecommendationMLTEnabled) {
          const moreLikeThisContainer = findContainerByLayout(containers, CONTAINER_LAYOUTS.MORE_LIKE_THIS);
          if (moreLikeThisContainer) {
            let similarItemsUri = moreLikeThisContainer.retrieveItems?.uri;
            if (similarItemsUri) {
              if (pcLevel) {
                similarItemsUri = addQueryParamsToString(
                  similarItemsUri,
                  { filter_pcLevel: pcLevel },
                  similarItemsUri.includes("?")
                );
              }
              dispatch(detailsPageActionMethod(appProvider, similarItemsUri, CONTAINER_TYPES.MORE_LIKE_THIS));
            }
          }
        }
      }
    }

    return () => {
      setContentMetadata(null);
    };
  }, [
    appProvider,
    containers,
    isRecommendationMLTEnabled,
    dispatch,
    pcLevel,
    history,
    detailsPageActionMethod,
    contentItemLayout,
    isTeamsPage,
    userProfile,
    isSportSeries,
  ]);

  // set metadata for the details page
  useEffect(() => {
    if (content) {
      const contentItemContainer = Array.isArray(content) && findContainerByLayout(content, contentItemLayout);
      if (contentItemContainer) {
        isUnifiedAsset.current = checkUnifiedAsset(contentItemContainer.metadata);
        if (isUnifiedAsset.current) {
          setContentMetadata(contentItemContainer);
        } else {
          history.push("/");
          dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
        }
      }
    }
  }, [content, dispatch, history, contentItemLayout]);

  // load on demand options for the details page
  useEffect(() => {
    if (isUnifiedAsset.current && containers && contentUserData) {
      const onDemandContainer = findContainerByLayout(containers, CONTAINER_LAYOUTS.ON_DEMAND);
      if (onDemandContainer && isUserLoggedIn) {
        let onDemandUri = onDemandContainer.retrieveItems?.uri;
        if (appProvider?.channelMapID) {
          onDemandUri = `${onDemandUri}&filter_regionId=${appProvider.channelMapID}`;
        }
        dispatch(detailsPageActionMethod(appProvider, onDemandUri, CONTAINER_TYPES.ON_DEMAND, cancelTokenSource));
      }
    }
  }, [contentUserData, detailsPageActionMethod, appProvider, cancelTokenSource, containers, dispatch, isUserLoggedIn]);

  // load live options for the details page
  useEffect(() => {
    if (isUnifiedAsset.current && containers && channelMapInfo) {
      const showTimesContainer = findContainerByLayout(containers, CONTAINER_LAYOUTS.SHOW_TIMES);
      if (showTimesContainer && isUserLoggedIn) {
        let showTimesUri = showTimesContainer.retrieveItems?.uri;
        if (appProvider?.channelMapID) {
          showTimesUri = `${showTimesUri}&filter_regionId=${appProvider.channelMapID}&sortOrder=asc`;
        }
        dispatch(detailsPageActionMethod(appProvider, showTimesUri, CONTAINER_TYPES.SCHEDULES));
      }
    }
  }, [channelMapInfo, detailsPageActionMethod, appProvider, containers, dispatch, isUserLoggedIn]);

  return { contentMetadata, isUnifiedAsset: isUnifiedAsset.current };
}
