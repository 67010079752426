import { LOAD_SEARCH_RESULTS, MANUAL_SEARCH_TRIGGERED } from "./actions";

const initialState = {
  content: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEARCH_RESULTS:
      return {
        ...state,
        content: action.content,
      };
    case MANUAL_SEARCH_TRIGGERED:
      return {
        ...state,
        manualSearchTriggered: action.isManualSearch,
      };
    default:
      return state;
  }
};
