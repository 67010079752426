import constants from "../constants";

const { IMAGES, CONTENT_ITEM_TYPES } = constants;

/**
 * Find the image aspect ratio based on width and height of a thumbnail
 *
 * @param {Number} width - thumbnail width in pixels
 * @param {Number} height - thumbnail height in pixels
 */
export const getImageAspectRatioByDims = (width, height) => {
  const ratio = width / height;
  if (ratio > 1.7 && ratio < 1.8) return IMAGES.ASPECT_RATIOS.DIM_9x16;
  return IMAGES.ASPECT_RATIOS.DIM_2x3;
};

/**
 * Fetch the keyart Image as needed from the AVS system
 *
 * @param {Object} contentItem  - contentItem Metadata
 * @param {String} aspectRatio - landscape or portrait image type
 * @param {Boolean} isUaSportAsset - checks if it is a UA sport asset.
 * @return {String} - Return  image url
 */
export const getAVSKeyArtImage = (contentItem, aspectRatio, isUaSportAsset = false) => {
  let imageUrl = "";

  if (contentItem && contentItem.extendedMetadata) {
    if (contentItem.extendedMetadata.dlum) {
      if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_2x3) imageUrl = contentItem.extendedMetadata.dlum.iconicImage23;
      else if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_9x16)
        imageUrl = contentItem.extendedMetadata.dlum.iconicImage169;
      else if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_3x4) imageUrl = contentItem.extendedMetadata.dlum.iconicImage34;
    }
  } else if (isUaSportAsset) {
    if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_9x16)
      imageUrl =
        contentItem?.entityType?.toLowerCase() === CONTENT_ITEM_TYPES.team
          ? contentItem.teamLogoUrl43
          : contentItem.seriesPoster169;
  }

  return imageUrl === "" ? contentItem?.pictureUrl ?? null : imageUrl;
};

/**
 * Fetch the Poster Image as needed from the AVS system
 *
 * @param {Object} contentItem  - contentItem Metadata
 * @param {String} aspectRatio - landscape or portrait image type
 * @param {boolean} isPosterImageFromSource - if poster images are available in source object
 * @return {String} - Return  image url
 */
export const getAVSPosterArtImage = (contentItem, aspectRatio, isPosterImageFromSource = false) => {
  let imageUrl = "";

  if (contentItem && contentItem.extendedMetadata) {
    if (contentItem.extendedMetadata.dlum) {
      if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_2x3)
        imageUrl =
          isPosterImageFromSource && contentItem?.extendedMetadata?.source?.image23
            ? contentItem.extendedMetadata.source.image23
            : contentItem.extendedMetadata.dlum.image23;
      else if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_9x16)
        imageUrl =
          isPosterImageFromSource && contentItem?.extendedMetadata?.source?.image169
            ? contentItem.extendedMetadata.source.image169
            : contentItem.extendedMetadata.dlum.image169;
      else if (aspectRatio === IMAGES.ASPECT_RATIOS.DIM_3x4)
        imageUrl =
          isPosterImageFromSource && contentItem?.extendedMetadata?.source?.image43
            ? contentItem.extendedMetadata.source.image43
            : contentItem.extendedMetadata.dlum.image34;
    }
  } else if (contentItem?.teamLogoUrl43) {
    imageUrl = contentItem.teamLogoUrl43;
  }

  return imageUrl === "" ? (contentItem?.pictureUrl ? contentItem.pictureUrl : null) : imageUrl;
};

/**
 * Handle image loading errors and set a fallback image
 *
 * @param {Object} error - Error event object
 * @param {String} fallbackImage - Fallback image
 */
export const handleImageError = (error, fallbackImage) => {
  error.target.onerror = null;
  error.target.src = fallbackImage;
};
