import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useOutsideClickIndicator from "../../shared/hooks/useOutsideClickIndicator";
import routeConstants from "../../shared/constants/routes";
import SearchButton from "../ImageButton";
import ImageButton from "../ImageButton";
import { loadSearchContent, triggerManualSearch } from "../../pages/SearchPage/state/actions";
import { useCachedResults } from "../../shared/hooks/useCachedResults";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import searchConstants from "../../shared/constants/search";
import { getPcLevelRestriction } from "../../shared/utils";
import "./style.scss";
const CrossIcon = `${process.env.PUBLIC_URL}/images/cross-icon.svg`;
const SearchIcon = `${process.env.PUBLIC_URL}/images/search-icon.png`;
const { SEARCH, SEARCH_VIEW_ALL } = routeConstants;
const { ENTER_KEY } = searchConstants;

/**
 * Search component with input box and search icon
 *
 * @component
 * @param {Object} props
 */
function Search(props) {
  const { loadSearchContent, appProvider, userProfile, featureToggles, triggerManualSearch, manualSearchTriggered } =
    props;
  const { isUserProfilesEnabled, isParentalPINEnabled, isSearchCollectionEnabled } = featureToggles;
  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const searchQuery = query.get("searchTerm") || "";
  const [searchBoxVisibility, setSearchBoxVisibility] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const searchBoxRef = useRef(null);
  const componentRef = useRef(null);
  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);
  const { t: translate } = useTranslation();

  const { status, data } = useCachedResults(
    appProvider,
    searchTerm.trim(),
    userProfile,
    pcLevel,
    manualSearchTriggered,
    isSearchCollectionEnabled,
    isKidsProfile
  );

  const focusSearchInputBox = () => {
    searchBoxRef.current.focus();
  };

  useEffect(() => {
    status === "fetched" && loadSearchContent(data);
    if (location.pathname === SEARCH.route || location.pathname === SEARCH_VIEW_ALL.route) {
      if (status === "fetched") {
        const currentHash = window.location.hash;
        let updatedHash;
        if (!currentHash.includes("searchTerm")) {
          updatedHash = currentHash + "?searchTerm=" + encodeURIComponent(searchTerm);
        } else {
          const startIndex = currentHash.indexOf("?");
          const endIndex = currentHash.indexOf("&") > 0 ? currentHash.indexOf("&") : currentHash.length;
          updatedHash = currentHash.replace(
            currentHash.substring(startIndex, endIndex),
            `?searchTerm=${encodeURIComponent(searchTerm)}`
          );
        }
        window.history.replaceState({}, currentHash, updatedHash);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Focusing search input box when it is visible
  useEffect(() => {
    if (searchBoxVisibility) {
      focusSearchInputBox();
      searchQuery && searchQuery === searchTerm && setSearchTerm(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBoxVisibility]);

  // using custom hook for identifying outside component click and hiding the searchBox
  const handleOutsideComponentClick = () => {
    setSearchBoxVisibility(false);
  };
  useOutsideClickIndicator(componentRef, handleOutsideComponentClick);

  // Direct loading of search route opens the search input box
  useEffect(() => {
    if (location.pathname === SEARCH.route) {
      setSearchBoxVisibility(true);
      searchQuery && setSearchTerm(searchQuery);
    } else if (location.pathname === SEARCH_VIEW_ALL.route) {
      setSearchBoxVisibility(false);
      searchQuery && setSearchTerm(searchQuery);
    } else {
      setSearchBoxVisibility(false);
      setSearchTerm("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const searchIconClickHandler = () => {
    setSearchBoxVisibility(true);
    focusSearchInputBox();
    if (searchTerm || (isSearchCollectionEnabled && !isKidsProfile)) {
      if (location.pathname !== SEARCH.route && location.pathname !== SEARCH_VIEW_ALL.route) {
        history.push(SEARCH.route);
      }
    }
  };

  // Handler method for search input box clear icon click
  const crossClickHandler = () => {
    searchBoxRef.current.value = "";
    setSearchTerm("");
    triggerManualSearch(false);
    focusSearchInputBox();
    history.push(SEARCH.route);
  };

  const inputChangeHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value.trim().length === 0) {
      triggerManualSearch(false);
      history.push(SEARCH.route);
    }
    //Redirecting to search route after user types in search bar.
    if (value.trim().length >= 3 && location.pathname !== SEARCH.route) {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SEARCH};${LINK_INFO.MEGA_NAV}`);
      history.push(SEARCH.route);
    }
  };

  const keyDownHandler = (event) => {
    if (event.key === ENTER_KEY && searchTerm !== "") {
      triggerManualSearch(true);
      history.push(SEARCH.route);
    }
  };

  return (
    <div ref={componentRef} className="search-container">
      <div className="search-box" onClick={searchIconClickHandler}>
        <input
          ref={searchBoxRef}
          value={searchTerm}
          className="search-input"
          type="text"
          onChange={inputChangeHandler}
          onKeyDown={keyDownHandler}
          placeholder={translate("search_hint")}
        />
        <SearchButton className="search-icon" src={SearchIcon} alt="search" testID="searchIcon" />
        {searchTerm.length > 0 && (
          <ImageButton
            className="cross-icon-container"
            onClickHandler={crossClickHandler}
            role="presentation"
            alt="crossIcon"
            src={CrossIcon}
          />
        )}
      </div>
    </div>
  );
}

Search.propTypes = {
  loadSearchContent: PropTypes.func.isRequired,
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
};

function mapStateToProps({ app, search }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
    manualSearchTriggered: search.manualSearchTriggered,
  };
}

const mapDispatchToProps = {
  loadSearchContent,
  triggerManualSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
