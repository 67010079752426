import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { showToastNotification } from "../../App/state/actions";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES, NOTIFICATION_TYPES } from "../../shared/constants/analytics";
import constants from "../../shared/constants";
import { useReducers } from "../../shared/hooks/useReducer";

const { REDUCER_TYPE } = constants;
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";

/**
 * Create Toast
 * @component
 * @param {Object} props
 */
function ToastNotification(props) {
  const { showToastNotification, appProvider, userProfile, isInHome } = props;
  const { toastData, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const [toast, setToast] = useState(false);
  const [toastClass, setToastClass] = useState(null);
  const { t: translate } = useTranslation();
  const { isConvivaAppTrackerEnabled } = featureToggles;
  useEffect(() => {
    if (toastData && Object.values(toastData).some((value) => value !== null)) {
      setToast(true);
      setToastClass("toast-Container show");

      // Tracking notification events
      const message = toastData.message;
      if (
        translate("message_in_home") === message ||
        translate("error_outside_region") === message ||
        translate("android_error_outside_region_2") === message ||
        translate("error_program_not_available_region") === message
      ) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.GEO_BLOCKED_ERROR, { message });
      } else if (toastData.message === translate("not_subscribed_restriction")) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, {
          media: toastData.analyticsInfo?.media,
          message,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS,
            getCustomContextMetadata(toastData.analyticsInfo?.media, userProfile, appProvider, isInHome)
          );
        }
      } else if (toastData.message === translate("error_browser_restriction")) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, {
          media: toastData.analyticsInfo?.media,
          notificationType: NOTIFICATION_TYPES.ALERTS,
          message,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS,
            getCustomContextMetadata(toastData.analyticsInfo?.media, userProfile, appProvider, isInHome)
          );
        }
      } else if (toastData.message === translate("panic_mode_enabled")) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, {
          notificationType: NOTIFICATION_TYPES.ALERTS,
          message,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS,
            getCustomContextMetadata(toastData.analyticsInfo?.media, userProfile, appProvider, isInHome)
          );
        }
      } else if (toastData.message && !toastData.message.includes("record")) {
        // We must omit recording since it creates a race condition for reporting on adobe xdm
        trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, { message });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS,
            getCustomContextMetadata(toastData.analyticsInfo?.media, userProfile, appProvider, isInHome)
          );
        }
      }

      const timeout = setTimeout(() => {
        setToastClass("toast-Container");
        showToastNotification(null, null, null);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [toastData, showToastNotification, translate, appProvider, userProfile, isInHome, isConvivaAppTrackerEnabled]);
  return toast && toastData ? (
    <div className={toastClass}>
      {toastData.icon && (
        <div className="icon-wrapper">
          <img src={toastData.icon} alt="" />
        </div>
      )}
      <div>{toastData.message}</div>
    </div>
  ) : (
    <></>
  );
}

const mapDispatchToProps = {
  showToastNotification,
};

export default connect(null, mapDispatchToProps)(ToastNotification);
