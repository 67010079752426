import styled from "styled-components";

const ToolTipWrapper = styled.div(
  ({ theme, position, $isFavChannelToolTipActive, gridHeight, width }) => `
  width: ${$isFavChannelToolTipActive ? "333px" : "373px"};
  height: ${$isFavChannelToolTipActive ? "100px" : "fit-content"};
  position: absolute;
  left: ${position == "favourite" ? "84px" : position == "program" ? "241px" : position == "resume" ? "210px" : "24px"};
  top: ${
    position == "calendar"
      ? "220px"
      : position == "favourite"
      ? gridHeight + 102 > 355
        ? "355px"
        : `${gridHeight + 102}px`
      : position == "program"
      ? gridHeight + 165 > 418
        ? "418px"
        : `${gridHeight + 165}px`
      : ""
  };
  bottom: ${position === "resume" ? (width > 1366 ? "295px" : "230px") : ""};
  z-index: 1;
  padding: 20px;
  background: ${theme.colours.white};
  color: ${theme.colours.black};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  `
);

const ToolTipHeading = styled.div(
  ({ theme, position }) => `
  width: ${position == "resume" ? "292px" : ""};
  font-size: ${theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeightSemiBold};
  line-height: 28.8px;
  margin-bottom: 8px;
  `
);

const ToolTipMessage = styled.div(
  ({ theme, $isFavChannelToolTipActive }) => `
  font-size: ${theme.typography.mediumFontSize};
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: 27px;
  text-align: left;
  margin-bottom: 8px;
  max-width: ${$isFavChannelToolTipActive ? "325px" : null};
  `
);
const ToolTipArrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;

  ${({ $showButton, position, theme }) => {
    if (position === "favourite") {
      return `
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid ${theme.colours.white};
        left: -12px;
        right:43px;
      `;
    } else if (position === "resume") {
      return `
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid ${theme.colours.white};
        bottom: -12px;
        left: 48px;
        `;
    } else {
      return `
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid ${theme.colours.white};
        bottom: ${$showButton ? "203px" : "151px"};
        top: -12px;
        left: 48px;
      `;
    }
  }}
`;

const ToolTipHeaderWrapper = styled.div(
  () => `
  display: flex;
  justify-content: space-between;
  `
);

const ToolTipCloseButton = styled.img(
  () => `
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  `
);

const ToolTipNavigationButton = styled.button(
  ({ theme }) => `
  font-size: ${theme.typography.mediumFontSize};
  font-weight: ${theme.typography.fontWeightSemiBold};
  background: #428400;
  color: ${theme.colours.white};
  border: none;
  padding: 10px 16px;
  margin: 4px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: ${theme.colours.primaryDark};
  }
  `
);

const ToolTipStepIndicator = styled.div(
  ({ theme }) => `
  font-size: ${theme.typography.mediumSwimlaneFontSize};
  font-weight:  ${theme.typography.fontWeightSemiBold};;
  text-align: center;
  color: ${theme.colours.scorpionGray};
  `
);

const TooltipButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-button {
    color: black;
    background: none;
  }
`;

export {
  ToolTipWrapper,
  ToolTipHeading,
  ToolTipMessage,
  ToolTipArrow,
  ToolTipHeaderWrapper,
  ToolTipCloseButton,
  ToolTipNavigationButton,
  ToolTipStepIndicator,
  TooltipButtonContainer,
};
