import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { HashRouter as Router, Switch, Redirect, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { WebClientTheme } from "../storybook/themes/Web-Client";
import GlobalStyle from "../storybook/themes/GlobalStyle";
import { isMobile } from "react-device-detect";
import { HelmetProvider } from "react-helmet-async";
import moment from "moment";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../shared/utils/localStorage";
import { handleLogin } from "../shared/utils/login";
import { getQueryParamsByName, isPanicMode, mapHarnessFeatureFlags } from "../shared/utils";
import { formatTrackingValue, getCustomerType, getMappedUserProfileType } from "../shared/utils/analytics";
import configureStore from "./store";
import SeoPageTags from "../components/SeoPageTags";
import AuthRoute from "../components/AuthRoute";
import BaseRoute from "../components/BaseRoute";
import FeedPage from "../pages/FeedPage";
import MovieDetailPage from "../pages/MovieDetailPage";
import SeriesDetailPage from "../pages/SeriesDetailPage";
import GuidePage from "../pages/GuidePage";
import CastDetailPage from "../pages/CastDetailPage";
import TopNav from "../components/TopNav";
import ProfileSetupModal from "../components/ProfileSetupModal";
import MobileView from "../pages/MobileView";
import middleware from "../shared/middleware";
import routeConstants from "../shared/constants/routes";
import SettingsPage from "../pages/SettingsPage";
import ViewAllPage from "../pages/ViewAllPage";
import SearchPage from "../pages/SearchPage";
import SvodViewAllPage from "../pages/SvodViewAllPage";
import SimilarItemsPage from "../pages/SimilarItemsPage";
import SubCategoryPage from "../pages/SubCategoryPage";
import PlayerPage from "../pages/PlayerPage";
import EpisodeDetailPage from "../pages/EpisodeDetailPage";
import SearchViewAllPage from "../pages/SearchViewAllPage";
import ErrorModal from "../components/ErrorModal/index";
import ToastNotification from "../components/ToastNotification";
import RecordingSettingsPanel from "../components/RecordingSettingsPanel";
import GateAccess from "../components/GateAccess";
import constants from "../shared/constants";
import recordingConstants from "../shared/constants/recordingConstants";
import storageConstants from "../shared/constants/storage";
import BasicsPage from "../pages/BasicsPage";
import { setCookie, expireCookie } from "../shared/utils/cookie";
import AccountPage from "../pages/AccountPage";
import SettingsDetailPage from "../pages/SettingsDetailPage";
import epgConstants from "../shared/constants/epg";
import ParentalPinPage from "../pages/ParentalPinPage";
import PurchasePinPage from "../pages/PurchasePinPage";
import SelectProfilePage from "../pages/SelectProfilePage";
import EditAllProfilesPage from "../pages/EditAllProfilesPage";
import EditProfilePage from "../pages/EditProfilePage";
import AddProfilePage from "../pages/AddProfilePage";
import ContentItemDetailsPage from "../pages/ContentItemDetailsPage";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../shared/utils/sessionStorage";
import { trackWebAction } from "../shared/analytics/dataLayer";
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_ERROR_INFO,
  SITE_NAMES,
  UNKNOWN_VALUE,
  LOGIN_STATE,
  MIDDLEWARE_PLATFORM,
  ADOBE_SDK,
} from "../shared/constants/analytics";
import { formatAppProviderLanguage, getHomeStatus, getPageType } from "../shared/analytics/helpers";
import RecordingsPage from "../pages/RecordingsPage";
import { logNREvent, setNRAttribute } from "../shared/analytics/newRelic";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../shared/constants/newRelic";
import packageJson from "../../package.json";
import useAppLanguage from "../shared/hooks/useAppLanguage";
import SpinningLoader from "../components/SpinningLoader";
import ECEManager from "../manager/ECEManager";
import CacheBuster from "../manager/CacheBuster";
import errorConstants from "../shared/constants/error";
import RedirectPIK from "../components/RedirectPIK";
import { getRecordingSystemType } from "../shared/utils/recordingHelper";
import AppLaunchManager from "../manager/AppLaunchManager";
import KeepAliveManager from "../manager/KeepAliveManager";
import { initialize, Event } from "@harnessio/ff-javascript-client-sdk";
import { getAVSAppChannel } from "../shared/utils";
import telusConvivaAnalytics from "../shared/utils/convivaAnalytics";

const { ERROR_MESSAGES, AVS_ERROR_CODES } = errorConstants;

const {
  FEED,
  PAGE,
  MOVIE_DETAIL_PAGE,
  SERIES_DETAIL_PAGE,
  GUIDE,
  SETTINGS,
  CAST_DETAIL_PAGE,
  VIEW_ALL_PAGE,
  SEARCH,
  SVOD_ALL_PAGE,
  SIMILAR_ITEMS,
  SUB_CATEGORIES_PAGE,
  LIVE_PLAYER,
  ON_DEMAND_PLAYER,
  RECORDING_PLAYER,
  EPISODE_DETAIL_PAGE,
  SEARCH_VIEW_ALL,
  BASICS,
  ACCOUNT,
  SETTING_DETAIL,
  PARENTAL_PIN,
  PURCHASE_PIN,
  RECORDINGS,
  PROFILE_SELECT,
  PROFILE_EDIT,
  PROFILE_ADD,
} = routeConstants;

const {
  USER_SESSION_RENEWAL_INTERVAL,
  IN_MARKET_OPTIK_URL,
  MODAL_TYPES,
  PACKAGE_NAME,
  APP_ENVIRONMENTS,
  USER_PROPERTY_NAMES,
} = constants;
const { RECORDING_PACKAGES, RECORDING_FEATURE_NAMES } = recordingConstants;
const { MY_SUBSCRIBED_CHANNELS, AUTH_CHANNEL_FILTERS, ALL_CHANNELS, UN_AUTH_CHANNEL_FILTERS } = epgConstants;
const {
  HAS_LOGGED_IN_BEFORE,
  HAS_SEEN_ONBOARDING_TUTORIAL,
  ACTIVE_PROFILE_USER_ID,
  USER_PROPERTY_TYPE,
  USER_HAS_LOGGED_IN,
  AVS_REFRESH_TOKEN,
  LANGUAGE,
  SELECTED_CHANNEL,
  LOGIN_REDIRECT_URL,
  CANARY,
  CACHED_ACCOUNT_BRAND,
  AUTH_USER_CONTENT,
  DEVICE_ID,
  GUIDE_RESTART,
} = storageConstants;

const { getAppProvider, getUserProfile, getUserProfileList, loginProfile, logoutProfile, getConfig, authenticateUser } =
  middleware;

function App() {
  const [initialStoreState, setInitialStoreState] = useState(null);
  const [gatePass, setGatePass] = useState("");
  const [gatePassError, setGatePassError] = useState(true);
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const secret = getQueryParamsByName("code");
  const state = getQueryParamsByName("state");
  const iamError = getQueryParamsByName("error");
  const loginBrand = getQueryParamsByName("login"); // this is to capture the brand of the login, when this param is present, it means we need to redirect to the brand specific login page
  const [hasGatedAccess, setHasGatedAccess] = useState(false);
  const [isProfileSelected, setIsProfileSelected] = useState(true);
  const [featureFlags, setFeatureFlags] = useState();
  const [panicState, setPanicState] = useState("none");
  const [keepAlive, setKeepAlive] = useState(USER_SESSION_RENEWAL_INTERVAL);
  const recordingFeatureNames = useRef([RECORDING_FEATURE_NAMES.CPVR, RECORDING_FEATURE_NAMES.MR]);

  let renewUserSessionTimerId;
  const loginRedirectUrl = getSessionStorage(LOGIN_REDIRECT_URL)?.replace(/[?&]login(=([^&#]*)|&|#|$)/, "");
  const isGenericUser = getLocalStorage(USER_HAS_LOGGED_IN);
  const hijackEnv = localStorage.getItem("env");
  const HARNESS_API_KEY = process.env.REACT_APP_HARNESS_KEY;
  const DATA_ENV = hijackEnv || process.env.REACT_APP_DATA_ENV || "avs-sit";
  const config = require("../shared/config/" + DATA_ENV + ".json");
  const appReady = useRef(false);
  const appLoginAttempt = useRef(false);
  const appObject = useRef();
  const appPanicMode = useRef();
  const appFeatureToggles = useRef();
  const appRecordingSystemType = useRef();
  const harnessInstance = useRef();
  const harnessErrored = useRef(false);
  const useRelay = useRef(false);

  /**
   * Used to pull down the initial featureflags from harness and kick start the app
   */
  useEffect(() => {
    setSessionStorage(CANARY, false);
    if (!loginBrand) {
      // if no login brand is present, we should pull down the featureflags from harness
      initializeHarness();
    } else {
      // if login brand is present, we redirect the user to the brand specific login page
      handleLoginRedirect();
    }

    return () => {
      harnessInstance.current?.off();
      harnessInstance.current?.close();
      clearInterval(renewUserSessionTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Creates a blank user object
   */
  const defaultUserProfile = useMemo(() => {
    return { isLoggedIn: null, userProfile: null, accessToken: null, errorInfo: null, masterAccountHouseholdID: null };
  }, []);

  const userProfile = useRef(defaultUserProfile);

  /**
   * Load profile data
   */
  const loadProfile = useCallback(
    async (provider, authUserContent) => {
      try {
        const newUserProfile = await getUserProfile(provider, authUserContent);
        userProfile.current = newUserProfile?.profile || defaultUserProfile;
        setKeepAlive(newUserProfile?.keepAlive ?? USER_SESSION_RENEWAL_INTERVAL);
        expireCookie(AVS_REFRESH_TOKEN);
      } catch (error) {
        console.info(error);
      }
    },
    [defaultUserProfile]
  );

  /**
   * handlePostHarnessUpdate will continue with the login flow and use the newly pull featureflags from harness based on userprofile.
   */
  const handlePostHarnessUpdate = useCallback(async () => {
    let isInHome = !userProfile.current?.user?.profile?.sessionProfileData?.isOutOfHome ?? false;
    let provider = getAppProvider(userProfile.current, isAppLanguageFrench, config, DATA_ENV, appPanicMode.current);
    let profileList;

    let featureToggles = mapHarnessFeatureFlags(featureFlags, provider, appPanicMode.current);
    provider = getAppProvider(
      userProfile.current,
      isAppLanguageFrench,
      config,
      DATA_ENV,
      appPanicMode.current,
      featureToggles.isPPVEnabled,
      featureToggles.isOAuthEnabled
    );

    if (appRecordingSystemType.current === RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP) {
      recordingFeatureNames.current = [RECORDING_FEATURE_NAMES.CPVR];
    } else if (appRecordingSystemType.current === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP) {
      recordingFeatureNames.current = [RECORDING_FEATURE_NAMES.MR];
    }

    if (featureToggles.isUserProfilesEnabled) {
      profileList = await getUserProfileList(provider);
      // If multiple profiles have been set up, determine which profile to log in to, if any
      if (profileList?.length > 1 && userProfile.current?.user?.profile?.profileData?.profileName?.length > 0) {
        let isValidProfileSelected = false;
        const isMasterAccountLoggedIn = userProfile.current?.user?.profile?.profileData?.isMasterAccount === "Y";
        const storedActiveUserId = getLocalStorage(ACTIVE_PROFILE_USER_ID);

        if (storedActiveUserId) {
          if (storedActiveUserId === userProfile.current?.user?.profile?.profileData?.userId) {
            isValidProfileSelected = true;
          } else {
            // Switch profile if current logged in profile does not match stored active profile
            const targetProfile = profileList.find((profile) => profile.userId === storedActiveUserId);
            if (targetProfile) {
              if (!isMasterAccountLoggedIn) {
                await logoutProfile(provider);
              }
              if (!targetProfile.isMaster) {
                await loginProfile(provider, storedActiveUserId);
              }
              await loadProfile(provider);
              isValidProfileSelected = true;
            }
          }
        }

        // Set a flag to redirect to profile selection page if we cannot determine which profile to use
        if (!isValidProfileSelected) {
          if (!isMasterAccountLoggedIn) {
            await logoutProfile(provider);
            await loadProfile(provider);
          }
          setIsProfileSelected(false);
        }
      }
    }

    if (!AUTH_CHANNEL_FILTERS().includes(getLocalStorage(SELECTED_CHANNEL))) {
      setLocalStorage(SELECTED_CHANNEL, MY_SUBSCRIBED_CHANNELS);
    }
    setLocalStorage(HAS_LOGGED_IN_BEFORE, true);

    appObject.current.provider = provider;
    appObject.current.featureToggles = {
      ...featureToggles,
      isRecordingEnabled: isRecordingFeatureEnabled(appRecordingSystemType.current, featureToggles),
      isPPVFeatureEnabled: isPPVFeatureEnabled(featureToggles),
    };
    appObject.current.profileList = profileList;
    appObject.current.userProfile = {
      ...userProfile.current,
      masterAccountHouseholdID: Array.isArray(profileList)
        ? profileList.find((profile) => profile?.isMaster)?.username
        : null,
    };
    appObject.current.isInHome = isInHome;
    launchApp(appObject.current);
  }, [DATA_ENV, config, featureFlags, isAppLanguageFrench, loadProfile]);

  /**
   * Function is used to create a harness connection for featureflags.
   */
  const initializeHarness = useCallback(() => {
    // If a harness connection already exist, we should remove all eventlisteners and close the connection
    harnessInstance.current?.off();
    harnessInstance.current?.close();
    const deviceId = getLocalStorage(DEVICE_ID);
    const harnessDataObj = {
      attributes: {
        app_name: "web",
        app_version: "web_" + packageJson.version,
        platform: getAVSAppChannel(config.general.AVS_config),
        build_number: packageJson.version,
        property: userProfile.current?.isLoggedIn
          ? userProfile.current?.user?.profile?.profileData?.properties?.[0]?.propertyName
          : "",
        user_property_type: userProfile.current?.isLoggedIn
          ? userProfile.current?.user?.profile?.profileData?.userPropertyType ?? getLocalStorage(USER_PROPERTY_TYPE)
          : "",
        crmaccountId: userProfile.current?.isLoggedIn
          ? userProfile.current?.user?.profile?.profileData?.crmaccountId
          : "",
        has_cpvr: userProfile.current?.isLoggedIn ? getRecordingSystemType(userProfile.current) !== undefined : false,
      },
    };
    const optionsObj = {
      streamEnabled: false, // Enable or disable streaming - default is enabled
      pollingEnabled: useRelay.current ? false : true, // Enable or disable polling - default is enabled if stream enabled, or disabled if stream disabled.
      pollingInterval: 60000, // Polling interval in ms, default is 60000ms which is the minimum.
    };

    if (useRelay.current) {
      optionsObj["baseUrl"] = "https://telus-ff-relayproxy.prod.g.svc.tv.telus.net";
    }

    if (deviceId) {
      harnessDataObj["identifier"] = deviceId;
      harnessDataObj["name"] = "deviceId";
    }
    // Create a harness connection to pull featureflags
    harnessInstance.current = initialize(HARNESS_API_KEY, harnessDataObj, optionsObj);
    harnessInstance.current.on(Event.READY, (flags) => {
      console.log("Harness is ready and featureflags are pulled in");
      setFeatureFlags(flags);
    });
    harnessInstance.current.on(Event.ERROR, () => {
      console.log("Harness general error occured");
      if (!useRelay.current && !featureFlags) {
        useRelay.current = true;
        initializeHarness();
      }
    });
    harnessInstance.current.on(Event.ERROR_AUTH, () => {
      console.log("Harness auth error occured");
      if (!useRelay.current && !featureFlags) {
        useRelay.current = true;
        initializeHarness();
      }
    });
    harnessInstance.current.on(Event.ERROR_FETCH_FLAGS, () => {
      console.log("Harness flags fetch error occured");
      if (!useRelay.current && !featureFlags) {
        useRelay.current = true;
        initializeHarness();
      }
    });
    harnessInstance.current.on(Event.ERROR_FETCH_FLAG, () => {
      console.log("Harness flag fetch error occure");
      if (!useRelay.current && !featureFlags) {
        useRelay.current = true;
        initializeHarness();
      }
    });
    harnessInstance.current.on(Event.ERROR_STREAM, () => {
      console.log("Harness stream error occured" + !featureFlags);
      if (!useRelay.current && !featureFlags) {
        useRelay.current = true;
        initializeHarness();
      }
    });
  }, [HARNESS_API_KEY, config.general.AVS_config, featureFlags]);

  /**
   * Used to determine if we need to pull down harness featureflags again using target information from the user profile.
   * Otherwise the application will launch using guest mode featureflags pull down previously
   */
  const checkForHarnessTargetUpdate = useCallback(async () => {
    if (userProfile.current?.isLoggedIn) {
      appLoginAttempt.current = true;
      if (harnessErrored.current !== true) {
        initializeHarness();
      } else {
        // If harness has errored out we shouldn't bother fetching harness again
        handlePostHarnessUpdate();
      }
    } else {
      if (!UN_AUTH_CHANNEL_FILTERS().includes(getLocalStorage(SELECTED_CHANNEL))) {
        setLocalStorage(SELECTED_CHANNEL, ALL_CHANNELS);
      }
      launchApp(appObject.current);
    }
  }, [initializeHarness, handlePostHarnessUpdate]);

  /**
   * Determines if the recording feature is enabled by looking at the feature flags and the user's recording system type
   * @returns True if the user is provisioned for MR recordings and the "RemoteRecordings" feature flag is enabled or
   *          if the user is provisioned for CPVR recordings and the "CloudPVR" feature flag is enabled, false otherwise
   */
  const isRecordingFeatureEnabled = (recordingSystemType, featureToggles) => {
    if (userProfile.current?.isLoggedIn) {
      const { isRemoteRecordingsEnabled, isCloudPVREnabled } = featureToggles;
      return (
        (isRemoteRecordingsEnabled && recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP) ||
        (isCloudPVREnabled && recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP)
      );
    }
    return false;
  };

  /**
   * Determines if the pay-per-view feature is enabled by looking at the feature flag and the user's tech packages
   * @returns True if the user is provisioned for PPV content and the "PPV" feature flag is enabled
   */
  const isPPVFeatureEnabled = (featureToggles) => {
    if (userProfile.current?.isLoggedIn) {
      const { isPPVEnabled } = featureToggles;
      if (isPPVEnabled) {
        if (userProfile.current.user?.profile?.profileData?.packageList?.length > 0) {
          for (let i = 0; i < userProfile.current.user.profile.profileData.packageList.length; i++) {
            const userPackage = userProfile.current?.user.profile.profileData.packageList[i];
            if (userPackage?.packageName?.toLowerCase() === PACKAGE_NAME.PPV) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  /**
   * Set up app initiation. check user sessions and create user profiles and app providers.
   * @param func setInitialStoreState useState
   */
  const initApp = useCallback(
    async (setInitialStoreState) => {
      let provider, featureToggles;
      let isInHome = false;
      let panicMode = false;
      let modalPopup = null;
      let fallbackMode;
      let profileList;
      let recordingSystemType;
      const language = getLocalStorage(LANGUAGE);

      window.addEventListener("storage", () => {
        const newLanguage = getLocalStorage(LANGUAGE);
        if (language !== newLanguage) {
          window.location.reload();
        }
      });
      if (isAppLanguageFrench) {
        moment.updateLocale("fr", {
          monthsShort: "janv_févr_mars_avr_mai_juin_juil_août_sept_oct_nov_déc".split("_"),
          weekdaysShort: "Dim_Lun_Mar_Mer_Jeu_Ven_Sam".split("_"),
          weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
          longDateFormat: {
            // We want to use different time formatting from the default French config
            // ex. 17 h 30 instead of 17:30
            LT: "H [h] mm",
            LTS: "HH [h] mm [m] ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH [h] mm",
            LLLL: "dddd D MMMM YYYY HH [h] mm",
            // Custom formats
            "[date_dropdown_format]": "ddd D MMM",
            "[date_format]": "ddd D MMM YYYY",
            "[schedule_date_format]": "D MMM",
            "[hour_format]": "H [h] 00",
          },
        });
      } else {
        moment.updateLocale("en", {
          longDateFormat: {
            // Custom formats
            "[date_dropdown_format]": "ddd MMM D",
            "[date_format]": "ddd MMM D YYYY",
            "[schedule_date_format]": "MMM D",
            "[hour_format]": "h:00 A",
          },
        });
      }

      let opusConfig;
      try {
        opusConfig = await getConfig(config);
      } catch (err) {
        opusConfig = err;
      }

      // ECE panic mode checking against config
      panicMode = ((featureFlags?.panic && JSON.parse(featureFlags?.panic)) || { state: "none" }).state;
      panicMode =
        panicMode === "full" || panicMode === "partial"
          ? true
          : panicMode === "legacy" && isPanicMode(opusConfig)
          ? true
          : false;

      provider = getAppProvider(
        defaultUserProfile,
        isAppLanguageFrench,
        config,
        DATA_ENV,
        false,
        false,
        featureFlags?.legacy_login_service === "enabled"
      );

      featureToggles = mapHarnessFeatureFlags(featureFlags, provider);

      setPanicState(featureToggles?.panic?.state);

      setNRAttribute(NR_CUSTOM_ATTRIBUTES.APP_BUILD, packageJson.version);
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.APP_ENV, provider.config.general.environment);

      // This is used to enable/disable the Fallback message that gates user access to the app
      fallbackMode = provider?.config?.general?.AVS_config?.fallbackMode;

      // 6.8 Throw away work for EMT
      const isBeta = provider?.config?.general?.environment === "AVS-PROD (Beta)";
      const gatingPass = isBeta ? localStorage.getItem("bypassGatePage68") : localStorage.getItem("bypassGatePage");

      if (fallbackMode === true) {
        setHasGatedAccess(gatingPass === "true");
      } else {
        localStorage.setItem("bypassGatePage", "false");
        setHasGatedAccess(true);
      }

      if (opusConfig?.headers?.["x-avs-sessionid"]) {
        setSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID, opusConfig.headers["x-avs-sessionid"]);
      }

      if (secret && state && !iamError) {
        try {
          expireCookie(AVS_REFRESH_TOKEN);
          let authUserContent = await authenticateUser(provider, secret, state);

          // Store and pulling of JSON payload if cassandra is down
          if (
            authUserContent?.data?.resultCode === "OK" &&
            authUserContent?.data?.errorDescription === AVS_ERROR_CODES.OK
          ) {
            setLocalStorage(AUTH_USER_CONTENT, JSON.stringify(authUserContent));
          } else {
            authUserContent = getLocalStorage(AUTH_USER_CONTENT);
            if (authUserContent?.length) {
              authUserContent = JSON.parse(getLocalStorage(AUTH_USER_CONTENT));
            }
          }

          setLocalStorage(SELECTED_CHANNEL, MY_SUBSCRIBED_CHANNELS);
          removeLocalStorage(ACTIVE_PROFILE_USER_ID);
          removeLocalStorage(USER_PROPERTY_TYPE);
          removeLocalStorage(GUIDE_RESTART);
          // Checking for the response headers accessible on client side from sessions call, to be removed once implementation is complete.
          console.log("<--checking-response-headers-->", authUserContent);
          await loadProfile(provider, authUserContent);
          if (authUserContent && authUserContent?.headers[AVS_REFRESH_TOKEN]) {
            setLocalStorage(USER_HAS_LOGGED_IN, true);
          }
          if (userProfile.current?.isLoggedIn) {
            // Set custom attributes here so they get included in the login complete event tracking
            setUserProfileAttributes(userProfile);
            setTimeout(() => {
              const isSPLUS =
                userProfile.current?.user?.profile?.profileData?.properties?.[0]?.propertyName ===
                USER_PROPERTY_NAMES.SPLUS;
              const currentRoute = window.location.hash.slice(1);
              const regExp = /[a-zA-Z]/g;
              const username = userProfile.current?.user?.profile?.profileData?.username;
              const firstTwoLettersString = username?.length >= 2 ? username.substring(0, 2) : "";
              const isTechTrial = regExp.test(firstTwoLettersString) && firstTwoLettersString !== "tq";
              let tmpProvider = getAppProvider(
                userProfile.current,
                isAppLanguageFrench,
                config,
                DATA_ENV,
                appPanicMode.current
              );

              const webPageDetails = {
                name: "",
                URL: window.location.href,
                server: window.location.origin,
                siteSection: "",
                _telus: {
                  pageName: "",
                  previousPage: getSessionStorage(ANALYTICS_STORAGE_KEYS.PREVIOUS_PAGE_NAME),
                  pageLoadTime: null,
                  customSiteSection: "",
                  primarySiteSection: "",
                  secondarySiteSection: null,
                  siteName: userProfile.current?.isLoggedIn
                    ? isSPLUS
                      ? USER_PROPERTY_NAMES.SPLUS.toLowerCase()
                      : provider?.isOptik
                      ? SITE_NAMES.OPTIK_TV
                      : SITE_NAMES.PIK_TV
                    : UNKNOWN_VALUE,
                  pageLanguage: formatAppProviderLanguage(tmpProvider.lang),
                  pageType: getPageType(currentRoute),
                  userLoginStatus: userProfile.current.isLoggedIn ? LOGIN_STATE.LOGGED_IN : LOGIN_STATE.LOGGED_OUT,
                  homeStatus: getHomeStatus(isInHome),
                  customerRegion: (tmpProvider.geoProvinceCode || "").toLowerCase() ?? null,
                  userHouseholdID:
                    (userProfile.current?.masterAccountHouseholdID ||
                      userProfile.current?.user?.profile?.profileData?.username) ??
                    null,
                  userAccountID: formatTrackingValue(tmpProvider.uuid),
                  geo: tmpProvider.geoCityName ? `geo-${formatTrackingValue(tmpProvider.geoCityName)}` : null,
                  packageId: null,
                  middlewarePlatform: MIDDLEWARE_PLATFORM,
                  sdkversion: ADOBE_SDK,
                  contentId: formatTrackingValue(""),
                  customerType: formatTrackingValue(getCustomerType(userProfile.current, isTechTrial)),
                  option82: formatTrackingValue(
                    tmpProvider.option82 || userProfile.current?.user?.profile?.option82?.[0]
                  ),
                  profile: userProfile.current?.user?.profile?.profileData?.userId ?? null,
                  profileType: getMappedUserProfileType(userProfile.current?.user?.profile?.profileData),
                  panicMode: tmpProvider.panicMode,
                  sessionId: formatTrackingValue(getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID) || ""),
                },
              };

              trackWebAction(ANALYTICS_EVENT_TYPES.LOGIN_COMPLETE, {}, webPageDetails);
            }, 5000);
          }
        } catch (error) {
          console.error(error);

          if (error?.message?.toLowerCase() === ERROR_MESSAGES.MULTI_GEO_ERROR) {
            modalPopup = {
              modalType: MODAL_TYPES.ERROR,
              modalContent: {
                className: "invalid-account-modal",
                title: "cookies_subscribed_optik_tv",
                message: "cookies_optiktv_app",
                isCloseable: false,
                buttons: [
                  {
                    label: translate("cookies_go_optiktv"),
                    onClickHandler: () => (window.location.href = IN_MARKET_OPTIK_URL),
                  },
                ],
                endButtonLabelOverride: "", // Removes default end button
              },
            };
          } else {
            modalPopup = {
              modalType: MODAL_TYPES.ERROR,
              modalContent: {
                title: "error",
                message: "login_error_message",
                disableRetry: true,
              },
            };
          }
          trackWebAction(ANALYTICS_EVENT_TYPES.ERROR, {
            name: ANALYTICS_ERROR_NAMES.USER_ERROR,
            details: ANALYTICS_ERROR_INFO.LOGIN_FAILURE,
            message: modalPopup.message,
            code: error?.code,
          });
          logNREvent(NR_PAGE_ACTIONS.LOGIN_ERROR, { message: error.message, errorResponse: JSON.stringify(error) });
        } finally {
          if (loginRedirectUrl) {
            window.location.href = loginRedirectUrl;
            removeSessionStorage(LOGIN_REDIRECT_URL);
          } else {
            const uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
              const cleanUri = uri.substring(0, uri.indexOf("?"));
              window.history.replaceState({}, document.title, cleanUri);
            }
          }
        }
      } else if (iamError) {
        modalPopup = {
          modalType: MODAL_TYPES.ERROR,
          modalContent: {
            title: "error",
            message: "login_error_message",
            disableRetry: true,
          },
        };
        logNREvent(NR_PAGE_ACTIONS.LOGIN_ERROR, { message: iamError });
      } else {
        if (isGenericUser || process.env.NODE_ENV === APP_ENVIRONMENTS.DEV) {
          await loadProfile(provider);
          const avsRefreshToken = getLocalStorage(AVS_REFRESH_TOKEN);
          if (avsRefreshToken) {
            setCookie(AVS_REFRESH_TOKEN, avsRefreshToken, 365);
          }
        }
      }

      setUserProfileAttributes(userProfile);

      if (userProfile.current?.errorInfo) {
        modalPopup = {
          modalType: MODAL_TYPES.ERROR,
          modalContent: userProfile.errorInfo,
        };
      }

      recordingSystemType = getRecordingSystemType(userProfile.current);

      // App related metadata used to launch the application
      appObject.current = {
        provider,
        userProfile: {
          ...userProfile.current,
          masterAccountHouseholdID: Array.isArray(profileList)
            ? profileList.find((profile) => profile?.isMaster)?.username
            : null,
        },
        profileList,
        isInHome,
        modalPopup,
        toastData: null,
        videoPlaying: { playing: false },
        reloadApp: false,
        recordingSettingsPanelData: null,
        isRequestLoading: false,
        spinningLoaderParams: null,
        displayChannelSidePanel: false,
        favouriteChannels: null,
        featureToggles: {
          ...featureToggles,
          isRecordingEnabled: isRecordingFeatureEnabled(recordingSystemType, featureToggles),
          isPPVFeatureEnabled: isPPVFeatureEnabled(featureToggles),
        },
        isAddedToFavourites: null,
        shouldUseContentItemDetailsPage: false, // this needs to be set as false to hide episode details page refactor changes
      };

      // Assign values to useRef and used after post harness fetch with user target information
      if (userProfile.current.isLoggedIn) {
        appPanicMode.current = panicMode;
        appFeatureToggles.current = featureToggles;
        appRecordingSystemType.current = recordingSystemType;
      }

      // Function used to determine if we'll need to fetch user information or proceed with guest mode
      await checkForHarnessTargetUpdate();

      if (!telusConvivaAnalytics.isConvivaAnalyticsInitialized()) {
        telusConvivaAnalytics.initConvivaAnalytics(provider, userProfile);
      }
    },
    [
      DATA_ENV,
      config,
      defaultUserProfile,
      featureFlags,
      iamError,
      isAppLanguageFrench,
      isGenericUser,
      loadProfile,
      loginRedirectUrl,
      secret,
      state,
      translate,
      userProfile,
      checkForHarnessTargetUpdate,
    ]
  );

  // Purpose of this useEffect to determine if we need to init the app or handle post user data fetching during app
  // init sequence
  useEffect(() => {
    if (featureFlags && appReady.current === false) {
      appReady.current = true;
      initApp(setInitialStoreState);
    } else if (userProfile.current.isLoggedIn && appLoginAttempt.current) {
      handlePostHarnessUpdate();
    }
  }, [featureFlags, initApp, handlePostHarnessUpdate]);

  // Used to set app state data to launch the app
  const launchApp = (app) => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, "");
    setInitialStoreState({
      app,
    });
  };

  const gatePassChange = (e) => {
    setGatePass(e.target.value);
  };

  const switchConfig = (message) => {
    localStorage.setItem("env", message.value);
  };

  const checkGatedPassLogging = () => {
    let isPassVerified = initialStoreState.app.provider.hasGatedAccess === gatePass;
    const isBeta = initialStoreState.app.provider?.config?.general?.environment === "AVS-PROD (Beta)";

    isBeta
      ? localStorage.setItem("bypassGatePage68", isPassVerified)
      : localStorage.setItem("bypassGatePage", isPassVerified);
    setGatePassError(isPassVerified);
    if (isPassVerified) window.location.reload();
  };

  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");

      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  }

  const showRedirectPage = window.location.host.indexOf(constants.PIK_WEB_SITE) !== -1;
  function handleMouseDownOnce() {
    document.body.classList.remove("user-is-tabbing");

    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  }
  /**
   * Redirects the user to the brand specific login page
   */
  function handleLoginRedirect() {
    if (loginBrand) {
      // if login brand is present, we redirect the user to the brand specific login page
      setLocalStorage(CACHED_ACCOUNT_BRAND, loginBrand.toLowerCase()); // we cache the login brand for user's authentication
      const provider = getAppProvider(defaultUserProfile, isAppLanguageFrench, config, DATA_ENV, false, false, false); // we need to get the provider to get the correct AGL URL and configs
      handleLogin(provider, loginBrand); // we redirect the user to the brand specific login page here
    }
  }

  window.addEventListener("keydown", handleFirstTab);

  if (initialStoreState && showRedirectPage) return <RedirectPIK />;
  return (
    initialStoreState && (
      <>
        {hasGatedAccess ? (
          <Provider store={configureStore(initialStoreState)}>
            <ThemeProvider theme={WebClientTheme}>
              <GlobalStyle />
              <CacheBuster
                currentVersion={packageJson.version}
                isEnabled={true} //If false, the library is disabled.
                isVerboseMode={true} //If true, the library writes verbose logs to console.
              >
                <Router>
                  <ScrollToTop />
                  {!userProfile.current?.isLoggedIn && !getLocalStorage(HAS_SEEN_ONBOARDING_TUTORIAL) && (
                    <Redirect to="/" />
                  )}
                  {!isProfileSelected && <Redirect to={PROFILE_SELECT.route} />}
                  <HelmetProvider>
                    <SeoPageTags keywords={["optik", "telus"]} />
                    {isMobile ? (
                      <MobileView />
                    ) : (
                      <>
                        <TopNav />
                        <Switch>
                          <BaseRoute exact path={["/", `${PAGE.route}/:id`, FEED.route]} component={FeedPage} />
                          <BaseRoute exact path={GUIDE.route} component={GuidePage} />
                          <AuthRoute
                            exact
                            path={RECORDINGS.route}
                            featureNames={recordingFeatureNames.current}
                            component={RecordingsPage}
                          />
                          <BaseRoute
                            exact
                            path={`${MOVIE_DETAIL_PAGE.route}/:uriType/:uriSubType/:itemType/:contentId/:preferredScheduleId?`}
                            component={
                              appObject.current.shouldUseContentItemDetailsPage
                                ? ContentItemDetailsPage
                                : MovieDetailPage
                            }
                          />
                          <BaseRoute
                            exact
                            path={`${SERIES_DETAIL_PAGE.route}/:uriType/:uriSubType/:itemType/:contentId`}
                            component={SeriesDetailPage}
                          />
                          <BaseRoute
                            exact
                            path={`${EPISODE_DETAIL_PAGE.route}/:uriType/:uriSubType/:itemType/:contentId/:preferredScheduleId?`}
                            component={
                              appObject.current.shouldUseContentItemDetailsPage
                                ? ContentItemDetailsPage
                                : EpisodeDetailPage
                            }
                          />
                          <BaseRoute exact path={CAST_DETAIL_PAGE.route} component={CastDetailPage} />
                          <BaseRoute exact path={SEARCH.route} component={SearchPage} />
                          <BaseRoute exact path={SEARCH_VIEW_ALL.route} component={SearchViewAllPage} />
                          <BaseRoute exact path={`${SIMILAR_ITEMS.route}/:itemId`} component={SimilarItemsPage} />
                          <BaseRoute exact path={`${SUB_CATEGORIES_PAGE.route}/:feedId`} component={SubCategoryPage} />
                          <BaseRoute path={`${VIEW_ALL_PAGE.route}/:url`} component={ViewAllPage} />
                          <BaseRoute
                            exact
                            path={[
                              `${SVOD_ALL_PAGE.route}/TRAY/SEARCH/:contentType`,
                              `${SVOD_ALL_PAGE.route}/:uriType/:uriSubType/:itemType/:contentId`,
                            ]}
                            component={SvodViewAllPage}
                          />
                          <AuthRoute
                            exact
                            path={[
                              LIVE_PLAYER.route,
                              ON_DEMAND_PLAYER.route + "/:playbackType",
                              RECORDING_PLAYER.route + "/:playbackType",
                            ]}
                            component={PlayerPage}
                          />
                          <BaseRoute exact path={SETTINGS.route} component={SettingsPage} />
                          <BaseRoute exact path={BASICS.route} component={BasicsPage} />
                          <AuthRoute exact path={ACCOUNT.route} component={AccountPage} />
                          <BaseRoute exact path={SETTING_DETAIL.route} component={SettingsDetailPage} />
                          <AuthRoute exact path={PARENTAL_PIN.route} component={ParentalPinPage} />
                          <AuthRoute exact path={PURCHASE_PIN.route} component={PurchasePinPage} />
                          <AuthRoute exact path={PROFILE_SELECT.route} component={SelectProfilePage} />
                          <AuthRoute exact path={PROFILE_EDIT.route} component={EditAllProfilesPage} />
                          <AuthRoute exact path={`${PROFILE_EDIT.route}/:id`} component={EditProfilePage} />
                          <AuthRoute exact path={PROFILE_ADD.route} component={AddProfilePage} />
                          <Redirect to="/" />
                        </Switch>
                        <RecordingSettingsPanel />
                        <ErrorModal />
                        <ProfileSetupModal />
                        <ToastNotification />
                        <SpinningLoader />
                        <ECEManager panicState={panicState} harnessOnFunc={harnessInstance.current?.on} />
                        <AppLaunchManager />
                        <KeepAliveManager keepAliveDelay={keepAlive} />
                      </>
                    )}
                  </HelmetProvider>
                </Router>
              </CacheBuster>
            </ThemeProvider>
          </Provider>
        ) : (
          <GateAccess
            gatePassError={gatePassError}
            gatePassChange={gatePassChange}
            checkGatedPassLogging={checkGatedPassLogging}
            config={config}
            avsConfigName={switchConfig}
          />
        )}
      </>
    )
  );
}

/**userProfile.current
 * Set New Relic custom attributes that are specific to the user
 * @param {Object} userProfile
 */
const setUserProfileAttributes = (userProfile) => {
  setNRAttribute(NR_CUSTOM_ATTRIBUTES.IS_LOGGED_IN, userProfile.current?.isLoggedIn ?? false);
  if (userProfile.current?.isLoggedIn) {
    setNRAttribute(
      NR_CUSTOM_ATTRIBUTES.IS_IN_HOME,
      !userProfile.current?.user?.profile?.sessionProfileData?.isOutOfHome ?? false
    );
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.USERNAME, userProfile.current?.user?.profile?.profileData?.crmAccountId);
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.UUID, userProfile.current?.user?.profile?.profileData?.uuidExternal);
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.USER_ID, userProfile.current?.user?.profile?.profileData?.userId);
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.SESSION_ID, getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID));
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.DEVICE_ID, getLocalStorage(DEVICE_ID));
    setNRAttribute(
      NR_CUSTOM_ATTRIBUTES.CUSTOMER_TYPE,
      userProfile.current?.user?.profile?.profileData?.properties?.[0]?.propertyName
    );
  }
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
