import React, { useMemo } from "react";

import Swimlane from "../../components/Swimlane";
import SwimlaneItem from "../SwimlaneItem";
import swimlaneConstants from "../../shared/constants/swimlane";
import createSwimlaneModels from "../../shared/utils/dataFilters";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { useReducers } from "../../shared/hooks/useReducer";
import { isItemTypeLive, isItemTypeRecording } from "../../shared/utils/content";
import { getRecordingSystemType, getRecordingInfo } from "../../shared/utils/recordingHelper";
import recordingConstants from "../../shared/constants/recordingConstants";
import constants from "../../shared/constants";

const {
  ITEM_TYPES: { TITLE_ITEM },
} = swimlaneConstants;

const { RECORDING_PACKAGES } = recordingConstants;
const { REDUCER_TYPE } = constants;

/**
 * A swimlane component that appears on the feed page. The main responsibility of this component
 * is to convert the feed config and data into a feed object to be consumed by the Swimlane component.
 *
 * @component
 */
function FeedPageSwimlane({
  data = [],
  config,
  totalItems,
  updateAvailableSwimlanes,
  setSelectedAssetRecordingInfo,
  updateRecordingHandler,
  refreshFeedRef,
}) {
  const { provider: appProvider, userProfile, channelMapInfo, recordingProfile } = useReducers(REDUCER_TYPE.APP);
  const { recordingsList } = useReducers(REDUCER_TYPE.RECORDING);
  const { currentPrograms } = useReducers(REDUCER_TYPE.EPG);
  const { DIMENSIONS, CONFIG } = feedLayoutMap[config.layout];
  const { isAppLanguageFrench } = useAppLanguage();
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );

  const enrichedData = useMemo(() => {
    if (data.length > 0) {
      return data.map((item) => {
        const enrichedItem = { ...item };
        if (
          (isItemTypeLive(item.metadata) || isItemTypeRecording(item.metadata)) &&
          item.channel?.channelId &&
          channelMapInfo?.containers
        ) {
          // Recordings & live program content from TRAY/EPG don't have much channel data included, so let's
          // use our cached channel info to enrich the item
          const cachedChannel = channelMapInfo.containers.find((channel) => {
            return channel.id === String(item.channel.channelId);
          });

          if (cachedChannel) {
            enrichedItem.channel = cachedChannel;
          }

          if (isItemTypeLive(item.metadata) && recordingsList) {
            // check if there is an associated recording for live assets
            const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;
            enrichedItem.recordingData = {
              isMR,
              recordingInfo: getRecordingInfo(isMR, enrichedItem, recordingsList, appProvider.channelMapID),
            };
          }
        }
        return enrichedItem;
      });
    }

    return data;
  }, [appProvider, channelMapInfo, data, recordingSystemType, recordingsList]);

  const feedObj = createSwimlaneModels({
    data: enrichedData,
    avsComponent: config,
    addParams: { DIMENSIONS, CONFIG },
    itemsCount: totalItems ?? data.length ?? 0,
    isAppLanguageFrench,
    userProfile,
    currentPrograms,
    appendFrenchTag: false,
    recordingProfile,
  });

  return (
    <Swimlane
      feed={feedObj}
      dimensionConfig={DIMENSIONS}
      sliderSettings={CONFIG}
      ThumbnailComponent={SwimlaneItem}
      updateRecordingHandler={updateRecordingHandler}
      setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
      getSwimlaneRowNum={updateAvailableSwimlanes?.(feedObj)}
      isFeedPageSwimlane={true}
      refreshFeedRef={refreshFeedRef}
      config={config}
      userProfile={userProfile}
    />
  );
}

/**
 * Maps a feed layout to a swimlane configuration
 */
const feedLayoutMap = {
  LARGE_CARDS: TITLE_ITEM.PORTRAIT,
  SMALL_CARDS: TITLE_ITEM.LANDSCAPE,
  "169large": TITLE_ITEM.LANDSCAPE,
};

export default FeedPageSwimlane;
