import React, { useEffect, useState } from "react";
import "./style.scss";

import Dropdown from "../Dropdown";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { seasonCompareFunction } from "../../shared/utils";
import { useReducers } from "../../shared/hooks/useReducer";
import constants from "../../shared/constants";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import i18n from "../../i18n.js";
import { getSortedSeasons } from "../../shared/utils/feedHelper.js";

const { REDUCER_TYPE } = constants;

/**
 * Season Dropdown component
 *
 * @component
 * @param {Object} props
 */
const SeasonPickerDropDown = ({
  onSeasonDropDownSelected,
  currentSeasonIndex,
  isRecordingPlayer,
  isTeamsPage,
  isSportSeries,
  sportsContent,
}) => {
  const { isAppLanguageFrench } = useAppLanguage();
  const {
    seasonContent: onDemandSeasonContent,
    seasonDetails: onDemandSeasonDetails,
    seriesDetails,
  } = useReducers(REDUCER_TYPE.ON_DEMAND_PLAYER);
  const { seasonEntitlements, seasonDetails, content } = useReducers(REDUCER_TYPE.SERIES_DETAILS);

  let seasonContent, episodeIndex, seriesContent;
  if (!isTeamsPage && !isSportSeries) {
    if (window.location.hash.includes("player")) {
      seasonContent = onDemandSeasonDetails;
      episodeIndex = onDemandSeasonContent;
      seriesContent = seriesDetails;
    } else {
      // Currently this block will be triggered only for series details page.
      seasonContent = seasonDetails?.length > 0 && seasonDetails.sort(seasonCompareFunction);
      episodeIndex = seasonEntitlements;
      seriesContent = content?.[0];
    }
  }
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [titleIndex, setTitleIndex] = useState(0);
  const sortedSeasonContent =
    !isTeamsPage && (isSportSeries ? sportsContent : getSortedSeasons(seriesContent, seasonContent));
  const seasonTitleArray = isRecordingPlayer
    ? [{ value: i18n.t("recordings") }]
    : isTeamsPage
    ? [{ value: i18n.t("live_tv_schedule") }]
    : getSeasonHeadings(sortedSeasonContent, episodeIndex, isAppLanguageFrench, sportsContent);

  useEffect(() => {
    setTitleIndex(currentSeasonIndex);
  }, [currentSeasonIndex]);

  const createSeasonList = () => {
    const list = {
      items: seasonTitleArray,
    };
    return list;
  };
  const buttonClickHandler = () => {
    setShowDropdownList(seasonTitleArray.length > 1);
  };

  const linkClickHandler = (item) => {
    const index = seasonTitleArray.findIndex((x) => x === item);
    const currentHash = window.location.hash;
    let updatedHash;
    if (!currentHash.includes("index")) {
      updatedHash = currentHash + "?index=" + index;
    } else {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.VIEW_DETAILS};${LINK_INFO.SEASON_PICKER_DROPDOWN}`);
      updatedHash = currentHash.replace(
        currentHash.substring(currentHash.indexOf("index"), currentHash.length),
        `index=${index}`
      );
    }

    window.history.replaceState({}, currentHash, updatedHash);
    setTitleIndex(index);
    onSeasonDropDownSelected(sortedSeasonContent[index]);
    setShowDropdownList(false);
  };

  /**
   * Hides the date dropdown pop up when clicked outside of the date dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
  };
  return (
    <Dropdown
      showDropdownList={showDropdownList}
      button={{
        label: seasonTitleArray[titleIndex]?.value,
        buttonClickHandler,
        contentSize: seasonTitleArray.length,
      }}
      lists={[
        {
          items: createSeasonList(),
          linkClickHandler,
          selectedItem: seasonTitleArray[titleIndex]?.value,
          listStyles: "season-picker-list",
        },
      ]}
      popUp={{
        popUpStyles: "seasonListContainer",
        style: { height: createSeasonList().length > 3 ? "300px" : "100px" },
        handleOutsideComponentClick,
      }}
    />
  );
};

export default SeasonPickerDropDown;

/**
 * Create list of string array for dorp down component
 * @param {Object} seasons
 * @param {Object} episodeIndex
 * @param {Boolean} isAppLanguageFrench
 * @param {Object} sportsContent
 * @returns {Array} array of string
 */
const getSeasonHeadings = (seasons, episodeIndex, isAppLanguageFrench, sportsContent) => {
  const modifiedSeasons = seasons?.length > 0 ? seasons : sportsContent;
  const titleArray =
    modifiedSeasons?.length > 0 &&
    modifiedSeasons.map((element) => {
      return {
        value: getSeasonHeading(element, episodeIndex, isAppLanguageFrench),
      };
    });
  return titleArray;
};

const getSeasonHeading = (seasonItem, episodeCount, isAppLanguageFrench) => {
  let seasonItemName,
    episodeItemName = "";
  if (seasonItem.metadata) {
    if (!seasonItem.games) {
      if (seasonItem.metadata.season) {
        seasonItemName = seasonItem.metadata.seasonAutoGenerated
          ? isAppLanguageFrench
            ? seasonItem.metadata.displaySeasonFra
            : seasonItem.metadata.displaySeason
          : i18n.t("season") + " " + seasonItem.metadata.season;
      } else if (seasonItem.metadata.title) {
        seasonItemName = seasonItem.metadata.title;
      }
      if (seasonItem.metadata.episodeCount) {
        episodeItemName = seasonItem.metadata.episodeCount > 1 ? i18n.t("episodes") : i18n.t("episode");
      } else if (episodeCount && episodeCount.length > 0) {
        episodeItemName = (episodeCount && episodeCount.length) > 1 ? i18n.t("episodes") : i18n.t("episode");
      }
    } else {
      seasonItemName =
        seasonItem.metadata.season !== "undefined" ? seasonItem.metadata.season : i18n.t("live_tv_schedule");
    }
  }
  return (
    <>
      {seasonItemName}&nbsp;
      {!seasonItem.games && (
        <span style={{ opacity: 0.5 }}>
          - {seasonItem?.metadata?.episodeCount || episodeCount?.length} {episodeItemName}
        </span>
      )}
    </>
  );
};
