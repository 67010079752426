/**
 * Configurations for swimlanes used throughout the application
 */
export default Object.freeze({
  ITEM_TYPES: {
    TITLE_ITEM: {
      LANDSCAPE: {
        CONFIG: {
          infinite: false,
          speed: 500,
          draggable: true,
          swipeToSlide: true,
          arrows: false,
        },
        DIMENSIONS: {
          values: {
            width: 324,
            height: 182,
            itemSpacing: 24,
            leftPadding: 45,
            borderRadius: "4px",
          },
          teamViewAllValues: {
            width: 200,
            height: 112,
            itemSpacing: 24,
            leftPadding: 45,
            borderRadius: "4px",
          },
          responsive: [
            {
              breakpoint: 768,
              values: {
                width: 215,
                height: 121,
                itemSpacing: 22,
                leftPadding: 27,
                borderRadius: "4px",
              },
            },
            {
              breakpoint: 1366,
              values: {
                width: 233,
                height: 131,
                itemSpacing: 24,
                leftPadding: 45,
                borderRadius: "4px",
              },
            },
          ],
        },
      },
      PORTRAIT: {
        CONFIG: {
          infinite: false,
          speed: 500,
          draggable: true,
          swipeToSlide: true,
          arrows: false,
        },
        DIMENSIONS: {
          values: {
            width: 166,
            height: 249,
            itemSpacing: 24,
            leftPadding: 45,
            borderRadius: "4px",
          },
          responsive: [
            {
              breakpoint: 768,
              values: {
                width: 159,
                height: 239,
                itemSpacing: 19,
                leftPadding: 27,
                borderRadius: "4px",
              },
            },
            {
              breakpoint: 1366,
              values: {
                width: 115,
                height: 173,
                itemSpacing: 14,
                leftPadding: 45,
                borderRadius: "4px",
              },
            },
          ],
        },
      },
      EP_LIST_LANDSCAPE: {
        CONFIG: {
          infinite: false,
          speed: 500,
          draggable: true,
          swipeToSlide: true,
          arrows: false,
        },
        DIMENSIONS: {
          values: {
            width: 324,
            height: 182,
            itemSpacing: 24,
            leftPadding: 45,
            borderRadius: "4px",
          },
          responsive: [
            {
              breakpoint: 768,
              values: {
                width: 215,
                height: 121,
                itemSpacing: 22,
                leftPadding: 27,
                borderRadius: "4px",
              },
            },
            {
              breakpoint: 1366,
              values: {
                width: 233,
                height: 131,
                itemSpacing: 24,
                leftPadding: 45,
                borderRadius: "4px",
              },
            },
          ],
        },
      },
    },
    CHARACTER_ITEM: {
      CONFIG: {
        infinite: false,
        speed: 500,
        draggable: true,
        swipeToSlide: true,
        arrows: false,
      },
      DIMENSIONS: {
        values: {
          width: 166,
          height: 166,
          itemSpacing: 17,
          leftPadding: 45,
          borderRadius: "50%",
        },
        responsive: [
          {
            breakpoint: 768,
            values: {
              width: 114,
              height: 114,
              itemSpacing: 10,
              leftPadding: 27,
              borderRadius: "50%",
            },
          },
          {
            breakpoint: 1366,
            values: {
              width: 156,
              height: 156,
              itemSpacing: 15,
              leftPadding: 45,
              borderRadius: "50%",
            },
          },
        ],
      },
    },
  },
  THUMBNAIL_TYPES: {
    TITLE_THUMBNAIL: "TITLE_THUMBNAIL",
    CHARACTER_THUMBNAIL: "CHARACTER_THUMBNAIL",
  },
  SWIMLANE_TITLES: {
    CAST_AND_CREW: "cast_and_crew",
    MOVIES: "Movies",
    TV_SHOWS: "TV Shows",
    LIVE: "Live",
    PVR: "recent recordings",
    SIMILAR_ITEMS: "more_like_this",
    PURCHASES: "purchases",
    RESUME: "resume",
    TEAMS: "teams",
    TRENDING: "trending now & upcoming",
    RECENT_AIRINGS: "recent_airings",
  },
  SWIMLANE_IDS: [
    "373b8a7b-64fa-47fc-9173-3ae55c308010",
    "f9dd9361-148c-45a7-ba9f-4b5834dca427",
    "4df7b25c-fd70-4d74-8503-1090bfc97c99",
    "Rentals_fr-ca",
    "Continue_fr-ca",
    "Pins_fr-ca",
  ],
  CLICK_TARGETS: {
    PLAY: "play",
  },
  MAX_SWIMLANE_ITEMS: 20,
});
